import {damsFetch} from "../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {useSnackbarDispatch, useSnackbarTranslation,} from "../snackbar/SnackbarContext";
import {clientLog} from "../clientLog";
import {showSnackbar} from "../app/showSnackbar";

export const useAddLicense = () => {
    const snackbarDispatch = useSnackbarDispatch();
    const t = useSnackbarTranslation();

    const successCallback = json => {
        return Promise.resolve({
            referenceType: "license",
            _action: "put",
            collectionId: json.collectionId,
            reference: {
                id: json.id,
                uniqueId: json.uniqueId,
                locale: json.locale,
                _action: "put",
                title: json.title,
                status: "published",
                source: json.source,
                sourceId: json.sourceId,
            },
        });
    };

    const errorCallback = error => {
        clientLog('error', error, 'useAddLicense');
        showSnackbar(snackbarDispatch, t("saveLicenseFailTitle", "Feil ved lagring av lisens"), t("saveLicenseFailBody", "Lisensen ble ikke lagret."), "error");
        return Promise.reject(error);
    };

    return ({licenses, locale = "no"}) => {
        return Promise.all(
            licenses.map((license) => {
                const newLicense = {
                    collectionId: license.collectionId,
                    schemaId: 1,
                    locale: locale,
                    _action: "put",
                    title: license.title,
                    status: "published",
                    source: license.source,
                    sourceId: license.sourceId,
                    documentType: "license",
                };

                return damsFetch(`/documents`, {
                    method: "POST",
                    body: JSON.stringify(decamelizeKeysDeep(newLicense)),
                })
                    .then(successCallback)
                    .catch(errorCallback)
            })
        );
    };
};
