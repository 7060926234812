import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {useMenuTranslation} from "./menuTranslationContext";
import {useIsAppAdmin} from "../auths/useIsAppAdmin";
import {useIsAdminOfAtleastOneMuseum} from "../auths/useIsAdminOfAtleastOneMuseum";
import {useAuthsState} from "../auths/authsContext";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import RestoreIcon from "@mui/icons-material/Restore";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import HomeIcon from "@mui/icons-material/Home";
import AssessmentIcon from '@mui/icons-material/Assessment';
import {MENU_CLOSED, useAppDispatch, useAppState} from "../app/AppContext";
import {useNavigate} from "react-router-dom";
import {useProjectState} from "../project/projectContext";
import {useHasArcheologyModule} from "../auths/useHasArcheologyModule";
import {ListItemButton} from "@mui/material";
import DataObjectIcon from '@mui/icons-material/DataObject';
import DatasetIcon from '@mui/icons-material/Dataset';
import {SET_PROJECT, useFileUploadDispatch} from "../damsfileupload/fileUploadContext";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {clientLog} from "../clientLog";
import ImportExportIcon from '@mui/icons-material/ImportExport';

const PREFIX = "Menu";

const classes = {
    drawer: `${PREFIX}-drawer`,
    drawerPaper: `${PREFIX}-drawerPaper`,
    drawerHeader: `${PREFIX}-drawerHeader`,
    drawerContainer: `${PREFIX}-drawerContainer`,
    drawerTitle: `${PREFIX}-drawerTitle`,
};

const StyledDrawer = styled(Drawer)(({theme}) => ({
    [`& .${classes.drawer}`]: {
        width: drawerWidth,
        flexShrink: 0,
    },

    [`& .${classes.drawerPaper}`]: {
        width: drawerWidth,
    },

    [`& .${classes.drawerHeader}`]: {
        backgroundImage: "url('/drawer-background.png')",
        backgroundSize: drawerWidth,
        backgroundRepeat: "no-repeat",
        minHeight: 183,
    },

    [`& .${classes.drawerContainer}`]: {
        overflow: "auto",
    },

    [`& .${classes.drawerTitle}`]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

const drawerWidth = 340;

export const Menu = () => {
    const {menuOpen, museumUserOrAdmin} = useAppState();
    const {userData} = useAuthsState();
    const dispatch = useAppDispatch();
    const t = useMenuTranslation();
    const isAppAdmin = useIsAppAdmin();
    const isMuseumAdmin = useIsAdminOfAtleastOneMuseum();

    const fileUploadDispatch = useFileUploadDispatch();

    useHasArcheologyModule();

    const {hasArcheologyModule} = useProjectState();
    clientLog('info', `has archeology module: ${hasArcheologyModule}`);

    const [externalUser, setExternalUser] = useState(true);
    const [contributor, setContributor] = useState(false);

    const navigate = useNavigate();

    const onMenuClick = (url) => {
        dispatch({type: MENU_CLOSED});
        navigate(url);
    };

    useEffect(() => {
        setContributor(userData['contributor']);
    }, [userData, contributor]);

    useEffect(() => {
        if (userData['appAccess']?.museums.length > 0) {
            setExternalUser(false);
        }
    }, [userData, externalUser]);

    const getMyFolders = () => {
        if (!contributor && !externalUser) {
            return (
                <ListItemButton onClick={() => onMenuClick("/my-folders/")}>
                    <ListItemIcon>
                        <FolderIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t("myFolders", "Mine mapper")}/>
                </ListItemButton>
            );
        }
    };

    const getAllFolders = () => {
        if (!contributor && !externalUser) {
            return (
                <ListItemButton onClick={() => onMenuClick("/all-folders/")}>
                    <ListItemIcon>
                        <FolderOpenIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t("allFolders", "Alle mapper")}/>
                </ListItemButton>
            );
        }
    };

    const folders = (
        <List
            subheader={
                <ListSubheader component={"div"} id={"folders-subheader"}>
                    {t("folders", "Mapper")}
                </ListSubheader>
            }
        >
            {getMyFolders()}
            <ListItemButton onClick={() => onMenuClick("/shared-folders/")}>
                <ListItemIcon>
                    <FolderSharedIcon/>
                </ListItemIcon>
                <ListItemText primary={t("sharedFolders", "Delte mapper")}/>
            </ListItemButton>
            {getAllFolders()}
        </List>
    );

    const files = (
        <List
            subheader={
                <ListSubheader component={"div"} id={"folders-subheader"}>
                    {t("files", "Filer")}
                </ListSubheader>
            }
        >
            <ListItemButton onClick={() => onMenuClick("/my-files/")}>
                <ListItemIcon>
                    <InsertDriveFileIcon/>
                </ListItemIcon>
                <ListItemText primary={t("myFiles", "Mine filer")}/>
            </ListItemButton>
        </List>
    );

    const projects = (
        <List
            subheader={
                <ListSubheader component={"div"} id={"projects-subheader"}>
                    {t('menuItemModules', 'Moduler')}
                </ListSubheader>
            }
        >
            <ListItemButton
                onClick={() => {
                    onMenuClick("/all-projects/");
                }}
            >
                <ListItemIcon>
                    <AccountTreeIcon/>
                </ListItemIcon>
                <ListItemText primary={t('menuItemProjects', 'Prosjekter')}/>
            </ListItemButton>
        </List>
    );

    const appAdminListItems = () => {
        if (isAppAdmin) {
            return (<>
                    <ListItemButton onClick={() => onMenuClick('/admin-local-assets')}>
                        <ListItemIcon>
                            <DatasetIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('adminLocalAssets', 'Lokale data')}/>
                    </ListItemButton>
                    <ListItemButton onClick={() => onMenuClick("/reindex")}>
                        <ListItemIcon>
                            <RestoreIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('reindex', 'Reindex')}/>
                    </ListItemButton>
                    <ListItemButton onClick={() => onMenuClick('/import')}>
                        <ListItemIcon>
                            <ImportExportIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('import', 'Import')}/>
                    </ListItemButton>
                </>
            );
        }
    };

    const museumAdminListItems = () => {
        if (isMuseumAdmin || isAppAdmin) {
            return (
                <ListItemButton
                    onClick={() => onMenuClick("/metadata-mapping")}
                >
                    <ListItemIcon>
                        <SyncAltIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t('metadataMapping', 'Metadata mapping')}/>
                </ListItemButton>
            );
        }
    };

    const fileUpload = () => {
        return (<ListItemButton onClick={() => {
            fileUploadDispatch({
                type: SET_PROJECT,
                projectId: undefined
            });
            onMenuClick('/upload');
        }}>
            <ListItemIcon>
                <UploadFileIcon/>
            </ListItemIcon>
            <ListItemText primary="Last opp fil"/>
        </ListItemButton>);
    };

    const fileUploadV3 = () => {
        return (<ListItemButton onClick={() => {
            onMenuClick('/upload3');
        }}>
            <ListItemIcon>
                <UploadFileIcon/>
            </ListItemIcon>
            <ListItemText primary="Last opp filer"/>
        </ListItemButton>);
    };

    const adminMenu = () => {
        if (isAppAdmin || isMuseumAdmin) {
            return (
                <List
                    subheader={
                        <ListSubheader component="div" id="shared-folders-subheader">
                            {t('admin', 'Admin')}
                        </ListSubheader>
                    }
                >
                    {appAdminListItems()}
                    {museumAdminListItems()}
                    <ListItemButton onClick={() => onMenuClick('/regeneratemetadata')}>
                        <ListItemIcon>
                            <DataObjectIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('regeneratemetadata', 'Regenerer Metadata')}/>
                    </ListItemButton>
                    <ListItemButton onClick={() => onMenuClick('/stats/')}>
                        <ListItemIcon>
                            <AssessmentIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('statistics', 'Statistikk')}/>
                    </ListItemButton>
                </List>
            );
        }
    };

    return (museumUserOrAdmin &&
        <StyledDrawer
            open={menuOpen}
            onClose={() => dispatch({type: MENU_CLOSED})}
            PaperProps={{
                elevation: 4,
            }}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}/>
            <div className={classes.drawerContainer}>
                <ListItemButton onClick={() => onMenuClick("/")}>
                    <ListItemIcon>
                        <HomeIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t('home', 'Hjem')}/>
                </ListItemButton>
                <Divider/>
                {fileUpload()}
                {fileUploadV3()}
                {!externalUser && folders}
                {!externalUser && files}
                {!externalUser && hasArcheologyModule && projects}
                {adminMenu()}
            </div>
        </StyledDrawer>
    );
};
