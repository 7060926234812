import {FILE_UPLOAD_OPERATION_ALL_FILES_DIALOG, useAppDispatch, useAppState} from "../../app/AppContext";
import {useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {clientLog} from "../../clientLog";
import {cancelOperation} from "../cancelOperation";

/**
 * A React component that displays a list of action buttons for an ongoing file upload operation.
 *
 * @param {string} operationId - The ID of the operation.
 * @returns {JSX.Element} The JSX element representing the action buttons.
 */
export const FileUploadOperationActionButtons = ({operationId}) => {

    const {operations} = useAppState();
    const appDispatch = useAppDispatch();

    const [operation, setOperation] = useState();
    const [lastMessage, setLastMessage] = useState();

    /**
     * Handles the click event for the "Vis alle filer" button.
     *
     * When the button is clicked, the `fileUploadOperationAllFilesDialog` action is dispatched with the operation ID and the `open` property set to `true`.
     * This will open the dialog showing all files for the operation.
     *
     * @private
     */
    const handleViewAllFilesClick = () => {
        appDispatch({
            type: FILE_UPLOAD_OPERATION_ALL_FILES_DIALOG,
            data: {
                open: true,
                operationId: operationId
            }
        });
    };

    /**
     * Handles the click event for the "Lukk" button on the file upload operation error dialog.
     *
     * When the button is clicked, the `CANCEL_OPERATION` action is dispatched with the operation to cancel.
     * This will cancel the operation and remove it from the list of operations.
     *
     * @private
     */
    const handleErrorClick = () => {
        cancelOperation(appDispatch, operation, (cancelledOperation) => {
            clientLog('info', `operation: ${cancelledOperation['jobId']} - cancelled by the user`, 'fileuploadoperationactionbuttons');
        });
    };

    /**
     * Returns a JSX element representing the action buttons for the operation.
     *
     * If the operation has finished, and no metadata should be extracted, or if the operation has finished and metadata has been extracted,
     * a button is displayed that allows the user to view all files.
     *
     * @returns {JSX.Element} The JSX element representing the action buttons.
     */
    const getActionButtons = () => {
        const {id, status, timedOut} = lastMessage;
        let actions = <></>;
        if (status === 'error') {
            actions = <Button onClick={handleErrorClick}>Lukk</Button>
        } else {
            if (timedOut) {
                actions = <Button onClick={handleErrorClick}>Lukk</Button>
            } else {
                if ((id === 'gettingobjectstatus' && !operation['extractMetadata']) || (id === 'extractingmetadata' && operation['extractMetadata'])) {
                    if (status === 'done') {
                        // NOTE:
                        // 1. Show button when all files have been processed, and no metadata should be extracted.
                        // 2. Show button when all files have been processed, and metadata has been extracted.
                        actions = <Button onClick={handleViewAllFilesClick}>Se igjennom</Button>;
                    }
                }
            }
        }
        return actions;
    };

    useEffect(() => {
        if (operations.length === 0 || !operationId) {
            return;
        }
        const operation = operations.find(o => o.jobId === operationId);
        if (!operation) {
            return;
        }

        setOperation(operation);

        const message = operation.workerMessages?.at(-1);
        if (!message) {
            return;
        }
        setLastMessage(message);
    }, [operations, operationId]);

    return operation
        && lastMessage
        && <Stack direction={"row"} alignContent={"top"} justifyContent={"end"}>
            {getActionButtons()}
        </Stack>
};