import {getMimeTypeFromFilename} from "../../damsFileObjectDefinitions";
import Chip from "@mui/material/Chip";
import React from "react";
import {DamsFileIcon} from "../../files/DamsFileIcon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {copyrightTypeValueToHr} from "../../utility";
import {format} from "../../app/dateFormat";

/**
 * Extracts the list of persons from the specified row's content.
 * @param {object} row A row from the operation files table.
 * @param {string} personType The type of person to extract, e.g. 'originator', 'donor', etc.
 * @returns {array} An array of objects with the persons.
 */
export const getPersons = (row, personType) => {
    const {content} = row;
    const {[personType]: persons} = content;
    return persons;
};

/**
 * Extracts the list of places from the specified row's content.
 * @param {object} row A row from the operation files table.
 * @returns {array} An array of objects with the places.
 */
export const getPlaces = (row) => {
    return row.content?.places || [];
};

/**
 * Extracts the document icon details from the specified row's content.
 * @param {object} row A row from the operation files table.
 * @returns {object} An object containing the document icon details, including mimeType and filename.
 */
export const getDocumentIconDetails = row => {
    const {content} = row;
    const {mediae} = content;
    const {reference} = mediae[0];
    const {title: filename} = reference;
    return {
        mimeType: getMimeTypeFromFilename(filename),
        filename: filename
    };
};

/**
 * Extracts the list of subjects from the specified row's content.
 * @param {object} row A row from the operation files table.
 * @returns {array} An array of objects with the subjects.
 */
export const getSubjects = (row) => {
    const {content} = row;
    const {subjects} = content;
    return subjects;
};

/**
 * Renders a table cell with a list of person chips.
 * @param {object[]} renderedCellValue - An array of person objects.
 * @returns {JSX.Element} A table cell containing person chips.
 */
export const renderPersonCell = renderedCellValue => {
    return renderedCellValue?.map(p => (_renderChip(p.reference.title)));
};

/**
 * Renders a table cell with a file icon and filename.
 * @param {object} renderedCellValue
 * @param {string} renderedCellValue.filename
 * @param {string} renderedCellValue.mimeType
 * @returns {JSX.Element}
 */
export const renderDocumentTypeCell = (renderedCellValue) => {
    return <DamsFileIcon filename={renderedCellValue.filename}
                         mimeType={renderedCellValue.mimeType}
                         size={36}/>
};

/**
 * Renders a table cell with a list of subject chips.
 * @param {object[]} renderedCellValue - An array of subject objects.
 * @returns {JSX.Element} A table cell containing subject chips.
 */
export const renderSubjectCell = (renderedCellValue) => {
    return renderedCellValue.map(s => (_renderChip(s.reference.title)));
};

/**
 * Renders a table cell with a list of place chips.
 * @param {object[]} renderedCellValue - An array of place objects.
 * @returns {JSX.Element} A table cell containing place chips.
 */
export const renderPlaceCell = (renderedCellValue) => {
    return renderedCellValue.map(s => (_renderChip(s.reference.title)));
};

/**
 * Renders a table cell with copyright information.
 * @param {object} t - The translation function.
 * @param {object} row - A row from the operation files table.
 * @returns {JSX.Element}
 */
export const renderCopyrightCell = (t, row) => {
    const {content} = row;
    if (!content) {
        return <></>;
    }

    const copyrightType = content?.copyrightType || '';
    const copyrightTypeDateUntil = content?.copyrightTypeDateUntil || '';
    const copyrightTypeOriginator = content?.copyrightTypeOriginator || [];
    const copyrightTypeResponsible = content?.copyrightTypeResponsible || [];

    return <Stack direction={"column"}>
        {copyrightType !== ''
            && <Typography variant={"caption"}>{copyrightTypeValueToHr(t, copyrightType)}</Typography>}
        {copyrightTypeDateUntil !== ''
            && copyrightTypeDateUntil.length > 0
            && <Typography variant={"caption"}>
                Gyldig til: {format(copyrightTypeDateUntil, 'P')}
            </Typography>}
        {copyrightTypeOriginator.length > 0
            && <Typography variant={"caption"}>
                Opphavsperson: {copyrightTypeOriginator.map(o => o.reference.title).join(', ')}
            </Typography>}
        {copyrightTypeResponsible.length > 0
            && <Typography variant={"caption"}>
                Forvaltet av: {copyrightTypeResponsible.map(o => o.reference.title).join(', ')}
            </Typography>}
    </Stack>;
};

/**
 * Renders a table cell with license information.
 * @param {object} t - The translation function.
 * @param {object} row - A row from the operation files table.
 * @returns {JSX.Element}
 */
export const renderLicenseCell = (t, row) => {
    const {content} = row;
    if (!content) {
        return <></>;
    }

    const licenses = content?.licenses || [];
    if (licenses.length === 0) {
        return <></>;
    }

    return <Stack direction={"column"}>
        {
            licenses.map(l => {
                return <Stack direction={"column"} key={`${l.reference.title}`}>
                    <Typography variant={"caption"}>{l.reference.title}</Typography>
                    {
                        l.responsible.length > 0
                        && <Typography variant={"caption"}>
                            Ansvarlige: {l.responsible.map(r => r.reference.nameAndTitle).join(', ')}
                        </Typography>
                    }
                </Stack>
            })
        }

    </Stack>;
};

/**
 * Renders a table cell with copyright clause information.
 * @param {object} t - The translation function.
 * @param {object} row - A row from the operation files table.
 * @returns {JSX.Element}
 */
export const renderCopyrightClauseCell = (t, row) => {
    const {content} = row;
    if (!content) {
        return <></>;
    }

    if (content?.copyrightInfo?.length === 0) {
        return <></>;
    }

    return <Stack direction={"column"}>
        {content.copyrightInfo?.map(c => {
            return <Stack direction={"column"} key={`${c.title}_${c.source}`}>
                <Typography variant={"caption"}>
                    {c.title} ({c.source})
                </Typography>
                {(c.fromDate != '' || c.toDate != '')
                    && <Typography variant={"caption"}>
                        Gyldig periode: {format(c.fromDate, 'P')} - {format(c.toDate, 'P')}
                    </Typography>
                }
                {c.responsible?.length > 0
                    && <Typography variant={"caption"}>
                        Ansvarlig: {c.responsible?.map(r => r.reference.nameAndTitle).join(', ')}
                    </Typography>
                }
            </Stack>
        })
        }
        {content.copyrightTerms != '' &&
            <Typography variant={"caption"}>
                {content.copyrightTerms}</Typography>
        }
    </Stack>;
};

const _renderChip = label => {
    return <Chip sx={{marginRight: '4px'}}
                 color={"secondary"}
                 label={label}/>
};
