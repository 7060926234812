/**
 * Returns true if there is at least one operation with progress details present (i.e. a status field
 * is present in the last message of the worker messages array) for the given operation type, false otherwise.
 *
 * @param {string} operationType - The type of operation to check.
 * @returns {boolean} True if there is at least one operation with progress details present, false otherwise.
 */
export const isProgressDetailsPresent = (operationType, operations) => {
    if (operationType === 'FILE_UPLOAD') {
        if (operations.length === 0) {
            return false;
        }
        return Boolean(operations.find(o => _workerMessageIsNotUndefined(o)));
    }
    return false;
};

const _workerMessageIsNotUndefined = operation => {
    if (!operation.workerMessages || operation.workerMessages.length === 0) {
        return false;
    }
    return typeof (operation.workerMessages.at(-1).status) !== 'undefined';
};