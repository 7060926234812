// ****************************************************************************************************************
//  This file contains functions related to the Uppy library, used by DAMS file uploader v3.
// *****************************************************************************************************************

import {clientLog} from "../clientLog";
import {getXQuoteMode} from "../app/damsFetch";
import {getMimeTypeFromFilename, getObjectTypeFromFilename} from "../damsFileObjectDefinitions";

const componentName = 'uppyfunctions';

/**
 * Returns a list of headers used when requesting an upload-URL from DMSF.
 * @param cid   int Collection ID
 * @returns {{"x-collection-id": *, "x-quote-mode": string, "Content-Type": string}}
 */
export const createRequestHeaders = (cid) => {
    return {
        'x-collection-id': cid,
        'x-quote-mode': getXQuoteMode(),
        'Content-Type': 'application/json'
    };
};

/**
 * Asynchronously retrieves the maximum resolution of the user's webcam.
 *
 * @return {Promise<{width: number, height: number}>} A promise that resolves to an object
 * containing the maximum width and height of the webcam.
 * @throws {Error} If no webcam is found, or is not accessible.
 */
export const getMaxResolution = async () => {
    try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');

        const constraints = {
            video: {
                deviceId: videoDevices[0].deviceId
            }
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        const track = stream.getVideoTracks()[0];
        const capabilities = track.getCapabilities();
        const settings = track.getSettings();

        track.stop();

        const maxWidth = capabilities.width?.max || settings.width;
        const maxHeight = capabilities.height?.max || settings.height;

        clientLog('info', `max camera resolution: ${maxWidth}x${maxHeight}`, componentName);

        return {width: maxWidth, height: maxHeight};
    } catch (error) {
        clientLog('error', error.message, componentName);
        clientLog('warn',
            'failed to retrieve camera resolution, using 1920x1080',
            componentName);
        return {width: 1920, height: 1080};
    }
};

/**
 * Creates an Uppy configuration, which is used to upload files to DMS.
 *
 * @param {number} cid The collection ID to upload files to.
 * @param {Function} uploadCompletedHandler The function to call when all files have been uploaded.
 * @return {Object} An object containing the Uppy configuration.
 */
export const getUppyConfig = (cid, uploadCompletedHandler) => {
    const headers = createRequestHeaders(cid);

    let commitHeaders = headers;
    delete commitHeaders["Content-Type"];   // EMSF-test does not accept this content-type when committing.

    return {
        finishedHandler: uploadCompletedHandler,
        orderUploadEndpoint: {
            url: `${window._env_.REACT_APP_DAMS_ADMIN_API}/v2/dms/order-upload-url/`,
            method: 'POST',
            headers: headers
        },
        commitMultipartEndpoint: {
            url: `${window._env_.REACT_APP_DAMS_ADMIN_API}/v2/dms/commit-multipart/`,
            headers: commitHeaders
        },
        chunkSize: 1e8,
        limit: 4,
        retry: 0,
        mock: false
    };
};

/**
 * Converts each of the files object in the the results returned when files are uploaded,
 * to the format used by the file upload operation.
 * @param files
 * @returns {*}
 */
export const uploadedFilesToOperationFiles = (files) => {
    return files.map(f => {
        const {uppyFile, dmsId, status} = f;
        const {data, source, name} = uppyFile;

        let filename = data.name;
        if (source === 'Webcam') {
            filename = name;
        }
        const objectType = getObjectTypeFromFilename(filename);
        const mimeType = getMimeTypeFromFilename(filename);

        return {
            dmsId: dmsId,
            status: status,
            mimeType: mimeType,
            filename: filename,
            size: data.size,
            objectType: objectType,
        };
    })
};

/**
 * Handler triggered before upload, in order to provide a valid mime type for all files.
 * (The browser does not always resolve the mime-type.)
 * @param files
 */
export const beforeUploadHandler = files => {
    for (let i = 0, max = Object.keys(files).length; i < max; i++) {
        const f = Object.values(files)[i];
        const filename = f.data.name;

        if (f.source !== 'Webcam' && (!f.data.type || f.data.type === '')) {
            const mimeType = getMimeTypeFromFilename(filename);
            if (mimeType) {
                // Construct a new file object with the new mime type, replacing the old one.
                f.data = new File([f.data], filename, {type: mimeType});

                // Update metadata for the Uppy object.
                f.meta.type = mimeType;
                f.type = mimeType;
            } else {
                const errMsg = `unable to resolve mime-type for: ${filename}`;
                clientLog('error', errMsg, componentName);
            }
        }
    }
};
