import React, {useEffect, useState} from "react";
import {useFoldersTranslation} from "../../folder/FoldersContext";
import {WithSaveCircle} from "../../app/WithSaveCircle";
import {useSnackbarDispatch} from "../../snackbar/SnackbarContext";
import {TOGGLE_REMOVE_FILES, useResultActionDispatch, useResultActionState,} from "../ResultActionContext";
import {useParams} from "react-router-dom";
import {useGetFolder} from "../../folder/useGetFolder";
import {Box, Grow, Modal, Paper,} from "@mui/material";
import {useRemoveFromFolder} from "../../folder/useRemoveFromFolder";
import {DeleteFilesModalActionButtons} from "./DeleteFilesModalActionButtons";
import {DeleteFilesModalHeader} from "./DeleteFilesModalHeader";
import PropTypes from "prop-types";
import {showSnackbar} from "../../app/showSnackbar";

/**
 * Renders a modal component for removing documents from a folder.
 *
 * @param {Object} props - The properties object.
 * @param {Array} props.selectedDocuments - The array of selected documents to be removed.
 * @param {Function} props.onSuccess - The callback function to be called after successful removal.
 * @return {JSX.Element} The rendered modal component.
 */
const RemoveFilesModal = ({selectedDocuments, onSuccess}) => {
    const {showRemoveFiles} = useResultActionState();
    const dispatch = useResultActionDispatch();
    const params = useParams();
    const t = useFoldersTranslation();
    const folder = useGetFolder(params.folderId);
    const snackbarDispatch = useSnackbarDispatch();
    const [documents, setDocuments] = useState(null);

    const [postDocumentsResponse, postDocuments] = useRemoveFromFolder();

    useEffect(() => {
        setDocuments(selectedDocuments);
    }, [selectedDocuments]);

    const onHide = () => {
        dispatch({type: TOGGLE_REMOVE_FILES});
    };


    const removeFilesHandler = () => {
        if (documents === null || documents.length === 0) {
            return;
        }

        // NOTE:
        // Folders are referenced by params.folderId
        // Projects are referenced by params.projectId
        const folderId = params.folderId || params.projectId;

        // Using Promise.allSettled() to continue even if saving of one document fails.
        Promise.allSettled(postDocuments(documents, folderId)).then(results => {
            const folderName = folder.data.title;
            const savedDocuments = [] || results.filter(r => r.status === 'fulfilled');
            showSnackbar(snackbarDispatch, t("removeFromFolderSuccessTitle", "Fjernet"), t(
                "removeFromFolderSuccessMessage",
                "{{count}} dokumenter ble fjernet fra mappen {{folderName}}",
                {
                    count: documents?.length,
                    folderName: folderName,
                }
            ), 'success');
            onHide();
            onSuccess(savedDocuments);
        });
    };

    return (
        <Modal
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            open={showRemoveFiles}
            closeAfterTransition
        >
            <Box sx={{outline: "none"}}>
                <WithSaveCircle saving={postDocumentsResponse.pendingDocuments > 0}>
                    <Grow in={showRemoveFiles}>
                        <Paper sx={{
                            padding: "32px",
                            height: "100%",
                        }}>
                            <DeleteFilesModalHeader t={t} modalType={'remove'} selectedDocuments={selectedDocuments}/>
                            <DeleteFilesModalActionButtons t={t}
                                                           cancelCallback={onHide}
                                                           deleteCallback={removeFilesHandler}/>
                        </Paper>
                    </Grow>
                </WithSaveCircle>
            </Box>
        </Modal>
    );
};

RemoveFilesModal.propTypes = {
    "selectedDocuments": PropTypes.any.isRequired,
    "onSuccess": PropTypes.func.isRequired
};

export {RemoveFilesModal};