import {clientLog} from "../clientLog";

export const useMetadataConfig = () => {

    const getMetadataConfig = (hasArcheologyModule) => {
        /*
          field - the type of field
          transKey - the key used to translate the field's title/name
          transDefaultValue - the default value for the field's title/name in norwegian
          transHelpKey - the key used to translate the field's help text
          transDefaultHelpValue - the default help text for the field in norwegian

          E.g.:
          <field>: {
            transKey: <key>,
            transDefaultValue: <default value>
            transHelpKey: <help key>
            transDefaultHelpValue: <default help value>
            values: []
          }

          NOTE:
          Data from this file is used both in the form when uploading a file,
          and in the metadata mapping form under admin.
         */

        // Default DAMS field list
        const defaultFieldList = {
            title: {
                fieldType: "text",
                required: true,
                transKey: "fieldTitle",
                transDefaultValue: "Tittel",
                transHelpKey: "fieldTitleHelp",
                transDefaultHelpValue: "En kort, beskrivende, tekst",
                values: [],
            },
            description: {
                fieldType: "text",
                transKey: "fieldDescription",
                transDefaultValue: "Beskrivelse",
                transHelpKey: "fieldDescriptionHelp",
                transDefaultHelpValue: "Omhandler motivet/opptaket",
                values: [],
            },
            customIdentifier: {
                fieldType: "text",
                transKey: "fieldCustomIdentifier",
                transDefaultValue: "Museumsnummer",
                transHelpKey: "fieldCustomIdentifierHelp",
                transDefaultHelpValue: "Unikt identifikasjonsnummer i samlingen",
                values: [],
            },
            productionDate: {
                fieldType: "date",
                transKey: "fieldProductionDate",
                transDefaultValue: "Produksjonsdato",
                transHelpKey: "fieldProductionDateHelp",
                transDefaultHelpValue: "Velg fra kalender",
                values: [],
            },
            producer: {
                fieldType: "producer",
                transKey: "fieldProducer",
                transDefaultValue: "Produsent",
                transHelpKey: "fieldProducerHelp",
                transDefaultHelpValue: "Søk, eller legg til ny",
                transCreateKey: "fieldProducerCreate",
                transDefaultCreateValue: "Legg til ny person",
                values: [],
            },
            persons: {
                fieldType: "persons",
                transKey: "fieldPersons",
                transDefaultValue: "Personer",
                transHelpKey: "fieldPersonsHelp",
                transDefaultHelpValue: "Som omtales/er avbildet",
                values: [],
            },
            // places: {
            //     fieldType: "places",
            //     transKey: "fieldPlaces",
            //     transDefaultValue: "Steder",
            //     transHelpKey: "fieldPlacesHelp",
            //     trnasDefaultHelpValue: "Legg til nytt sted",
            //     values: [],
            // },
            // subjects: {
            //     fieldType: "subjects",
            //     transKey: "fieldSubjects",
            //     transDefaultValue: "Emneord",
            //     values: [],
            // },
            // licenses: {
            //     fieldType: "licenses",
            //     transKey: "fieldLicenses",
            //     transDefaultValue: "Lisenser",
            //     transHelpKey: "fieldLicensesHelp",
            //     transDefaultHelpValue: "Velg fra listen",
            //     values: [],
            // },
            remarks: {
                fieldType: "text",
                transKey: "fieldRemarks",
                transDefaultValue: "Kommentar",
                transHelpKey: "fieldRemarksHelp",
                transDefaultHelpValue: "Ytterligere informasjon",
                values: [],
            },
            languages: {
                metadataMapper: false,
                fieldType: "languages",
                transKey: "fieldLanguages",
                transDefaultValue: "Språk",
                transHelpKey: "fieldLanguagesHelp",
                transDefaultHelpValue: "Som brukes i opptaket/teksten",
                values: [],
            },
            relations: {
                metadataMapper: false,
                fieldType: "relations",
                transKey: "fieldRelations",
                transDefaultValue: "Relaterte objekter",
                transHelpKey: "fieldRelationsHelp",
                transDefaultHelpValue:
                    "Søk etter andre objekter i DAMS på ID, eller tittel/beskrivelse",
                values: [],
            },
        };

        /*
         Each of the field configurations are put under a property reflecting the objectType:
         <objectType> : {
            tabTitle: <translationKey>#<default value in norwegian>,
            fields: [<field config>]
         }
         E.g. tabImage#Bilde => key: tabImage, default value: "Bilde"
        */

        const defaultFieldConfig = {
            image: {
                tabTitle: "tabImage#Bilde",
                fields: defaultFieldList,
            },
            video: {
                tabTitle: "tabVideo#Video",
                fields: defaultFieldList,
            },
            audio: {
                tabTitle: "tabAudio#Lyd",
                fields: defaultFieldList,
            },
            document: {
                tabTitle: "tabDocument#Dokument",
                fields: defaultFieldList,
            },
            model: {
                tabTitle: "tabModel#Modell",
                fields: defaultFieldList,
            },
        };

        // Archeology project object field lists
        const photoFieldList = {
            project: {
                transKey: "fieldProject",
                transDefaultValue: "Prosjekt",
                values: [],
            },
            locality: {
                transKey: "fieldLocality",
                transDefaultValue: "Lokalitet",
                values: [],
            },
            seaFindingsNumber: {
                transKey: "fieldSeaFindingsNumber",
                transDefaultValue: "Sjøfunn nr",
                values: [],
            },
            askeladdenId: {
                transKey: "fieldAskeladdenId",
                transDefaultValue: "Askeladden ID",
                values: [],
            },
            boatNumber: {
                transKey: "fieldBoatNumber",
                transDefaultValue: "K. nr, Båt nr.",
                values: [],
            },
            boatPartNumber: {
                transKey: "fieldBoatPartNumber",
                transDefaultValue: "Profil, Båtdelsnr",
                values: [],
            },
            objectNumber: {
                transKey: "fieldObjectNumber",
                transDefaultValue: "Gjenstandsnr, detalj",
                values: [],
            },
            remarks: {
                transKey: "fieldRemarks",
                transDefaultValue: "Fritekst",
                values: [],
            },
            keywords: {
                transKey: "fieldKeywords",
                transDefaultValue: "Nøkkelord",
                values: [],
            },
            datingMethod: {
                transKey: "fieldDatingMethod",
                transDefaultValue: "Dateringsmetode",
                values: [],
            },
            centuryNumber: {
                transKey: "fieldCenturyNumber",
                transDefaultValue: "Århundre",
                values: [],
            },
            year: {
                transKey: "fieldYear",
                transDefaultValue: "Årstall",
                values: [],
            },
            photographer: {
                transKey: "fieldPhotographer",
                transDefaultValue: "Fotograf",
                values: [],
            },
            captionWriter: {
                transKey: "fieldCaptionWriter",
                transDefaultValue: "Tekstforfatter",
                values: [],
            },
            credit: {
                transKey: "fieldCredit",
                transDefaultValue: "Krediteres",
                values: [],
            },
            cameraNumber: {
                transKey: "fieldCameraNumber",
                transDefaultValue: "Kameranr",
                values: [],
            },
            photoBookNumber: {
                transKey: "fieldPhotoBookNumber",
                transDefaultValue: "Fotoboknr",
                values: [],
            },
            originFilename: {
                transKey: "fieldOriginFilename",
                transDefaultValue: "Navn originalfil",
                values: [],
            },
        };

        const tableFieldList = defaultFieldList;
        const geodataFieldList = defaultFieldList;

        // Add field configs. to the list of available model tabs.
        const archeologyProjectFieldConfig = {
            photo: {
                tabTitle: "tabPhotography#Fotografi",
                fields: photoFieldList,
            },
            table: {
                tabTitle: "tabTable#Tabell",
                fields: tableFieldList,
            },
            geodata: {
                tabTitle: "tabGeodata#Geodata",
                fields: geodataFieldList,
            },
        };

        // Field config
        if (!hasArcheologyModule) {
            return defaultFieldConfig;
        } else {
            return {...defaultFieldConfig, ...archeologyProjectFieldConfig};
        }
    };

    const getDocumentTypeConfig = (documentType) => {
        const c = getMetadataConfig();
        if (!c || !Object.keys(c).includes(documentType)) {
            clientLog('error', `failed to get config for the ${documentType} documentType`);
            return;
        }
        return c[documentType];
    };

    return [getMetadataConfig, getDocumentTypeConfig];
};
