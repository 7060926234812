// {
//     "uniqueId": "3e2da832-018e-49a7-92d3-65a3bdf73322",
//     "createdAt": "2024-09-27 12:34:23.765214",
//     "updatedAt": "2024-09-27 12:35:09.540103",
//     "id": 73,
//     "schemaId": 1,
//     "documentType": "StillImage",
//     "title": "brad-starkey-fcSPN9Uo7yI-unsplash_ff8123f6-0e6d-4672-bfde-22298b8de23e_c8af9a3f-a6f9-4635-b4b9-c355043cda12.jpg",
//     "description": "0638YQH4TLTZ.jpg",
//     "locale": "no",
//     "content": {
//     "mediae": [
//         {
//             "reference": {
//                 "id": 72,
//                 "title": "brad-starkey-fcSPN9Uo7yI-unsplash_ff8123f6-0e6d-4672-bfde-22298b8de23e_c8af9a3f-a6f9-4635-b4b9-c355043cda12.jpg",
//                 "locale": "no",
//                 "source": "dms",
//                 "status": "published",
//                 "mimeType": "image/jpeg",
//                 "schemaId": 1,
//                 "sourceId": "0638YQH4TLTZ",
//                 "uniqueId": "c3b0f006-7cb0-4e28-81e9-323e93fed5e2",
//                 "createdAt": "2024-09-27T12:34:23",
//                 "updatedAt": "2024-09-27T12:34:23",
//                 "documentType": "media",
//                 "fileExtension": "jpg"
//             },
//             "referenceType": "media"
//         }
//     ],
//         "subjects": [],
//         "languages": [],
//         "customIdentifier": ""
// },
//     "status": "published",
//     "collectionId": 3,
//     "createdById": 2,
//     "updatedById": 2,
//     "referenceCount": 0,
//     "parent": null,
//     "updatedByName": "Jonas Andreas Olstad",
//     "createdByName": "Jonas Andreas Olstad"
// }


import React, {useCallback, useEffect, useMemo, useState} from "react";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {DialogSelectFields} from "./DialogSelectFields";
import {DocumentProvider} from "../../documents/documentContext";
import {
    FILE_UPLOAD_OPERATION_EDIT_METADATA_DIALOG,
    FILE_UPLOAD_OPERATION_SELECT_FIELDS_DIALOG,
    useAppDispatch,
    useAppState,
    useAppTranslation
} from "../../app/AppContext";
import {DialogMetadataForm} from "./DialogMetadataForm";
import {SnackbarProvider} from "../../snackbar/SnackbarContext";
import {CopyrightProvider} from "../../copyright/copyrightContext";
import {
    BatchEditProvider,
    SELECT_FIELDS,
    UNSELECT_ALL_FIELDS,
    useBatchEditDispatch
} from "../../documents/batchEditContext";
import {damsFetch} from "../../app/damsFetch";
import {
    getDocumentIconDetails,
    getPersons,
    getPlaces,
    getSubjects,
    renderCopyrightCell,
    renderCopyrightClauseCell,
    renderDocumentTypeCell,
    renderLicenseCell,
    renderPersonCell,
    renderPlaceCell,
    renderSubjectCell
} from "./operationFilesTableHelpers";
import {getHrDateAndTime} from "../../utility";
import {format} from "../../app/dateFormat";
import {decamelize} from "../../app/decamelize";


/**
 * A component that displays a list of files for a given operation in a table.
 * The component is used in the file upload operation details page.
 *
 * The component uses the `useMaterialReactTable` hook to create a table with
 * the list of files. The table is populated with data from the server by sending
 * a POST request to the `/jobs/file-upload/get-files` endpoint with the
 * operation ID as parameter. The response is a list of files, which is then
 * set as the table data.
 *
 * The component also displays a button above the table that allows the user
 * to select which fields to edit in the files. When the user clicks the button,
 * a dialog is opened that allows the user to select the fields. The selected
 * fields are stored in the batch edit context and the edit metadata form is
 * displayed with the selected fields.
 *
 * When the user clicks the "Lagre" button in the edit metadata form, the
 * component resets the selected table rows and reloads the list of files in
 * the table.
 *
 * @param {object} props - The component props.
 * @param {string} props.operationId - The ID of the operation.
 * @param {boolean} props.loadData - Whether to load the list of files.
 * @returns {ReactElement} The component.
 */
export const OperationFilesTable = ({operationId, loadData}) => {

    const t = useAppTranslation();
    const appDispatch = useAppDispatch();
    const batchEditDispatch = useBatchEditDispatch();

    const {fileUploadOperationEditFiles} = useAppState();

    // const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState({});

    const [data, setData] = useState([]);

    const columns = useMemo(() => [{
        accessorKey: 'uniqueId',
        header: 'Unique ID',
        enableSorting: false,
        enableHiding: true
    }, {
        header: 'Dokumenttype',
        accessorFn: row => getDocumentIconDetails(row),
        Cell: ({renderedCellValue}) => renderDocumentTypeCell(renderedCellValue),
        enableSorting: true,
        enableHiding: false
    }, {
        accessorKey: 'title',
        header: 'Tittel',
        enableSorting: true,
        enableHiding: false
    }, {
        accessorKey: 'description',
        header: 'Beskrivelse',
        enableSorting: false,
    }, {
        accessorKey: 'productionDate',
        header: 'Produksjonsdato',
        enableSorting: true,
        enableHiding: true,
        accessorFn: row => {
            return format(row.content.productionDate, 'P')
        },
        Cell: ({renderedCellValue}) => {
            return renderedCellValue;
        }
    }, {
        accessorKey: 'producer',
        accessorFn: row => getPersons(row, 'producer'),
        Cell: ({renderedCellValue}) => renderPersonCell(renderedCellValue),
        header: 'Fotograf/Produsent',
        enableSorting: false
    }, {
        accessorKey: 'persons',
        accessorFn: row => getPersons(row, 'persons'),
        Cell: ({renderedCellValue}) => renderPersonCell(renderedCellValue),
        header: 'Personer',
        enableSorting: false
    }, {
        accessorKey: 'places',
        accessorFn: row => getPlaces(row),
        Cell: ({renderedCellValue}) => renderPlaceCell(renderedCellValue),
        header: 'Steder',
        enableSorting: false,
    }, {
        accessorKey: 'subjects',
        accessorFn: row => getSubjects(row),
        Cell: ({renderedCellValue}) => renderSubjectCell(renderedCellValue),
        header: 'Emneord',
        enableSorting: false
    }, {
        accessorKey: 'copyright',
        header: 'Opphavsrett',
        enableSorting: false,
        enableHiding: true,
        accessorFn: (row) => renderCopyrightCell(t, row)
    }, {
        accessorKey: 'licenses',
        header: 'Lisenser',
        enableSorting: false,
        enableHiding: true,
        accessorFn: row => renderLicenseCell(t, row)
    }, {
        accessorKey: 'copyrightClause',
        header: 'Klausuler',
        enableSorting: false,
        enableHiding: true,
        accessorFn: row => renderCopyrightClauseCell(t, row)
    }, {
        accessorKey: 'createdByName',
        header: 'Opprettet av',
        enableSorting: true
    }, {
        accessorKey: 'createdAt',
        header: 'Opplastet dato',
        enableSorting: true,
        enableHiding: false,
        accessorFn: row => getHrDateAndTime(row.createdAt),
        Cell: ({renderedCellValue}) => {
            return renderedCellValue;
        }
    }, {
        accessorKey: 'updatedAt',
        header: 'Sist endret',
        enableSorting: true,
        accessorFn: row => getHrDateAndTime(row.updatedAt),
        Cell: ({renderedCellValue}) => {
            return renderedCellValue;
        }
    }], [t]);

    const table = useMaterialReactTable({
        columns,
        data,
        enableSelectAll: true,
        enableMultiRowSelection: true,
        enableRowSelection: true,       // enables the checkbox for each row
        enableColumnFilters: false,     // removes the filter option, placed above the table, to the right
        enableColumnOrdering: false,    // removes the drag'n drop column reordering option
        enableDensityToggle: false,
        enableStickyHeader: true,
        enableColumnActions: false,
        selectAllMode: 'all',
        initialState: {
            density: 'compact',
            sorting: [{id: 'title', desc: false}],
        },
        getRowId: originalRow => originalRow.uniqueId,
        localization: {
            sortByColumnAsc: 'Sorter {column} stigende',
            sortByColumnDesc: 'Sorter {column} synkende',
            sortedByColumnAsc: 'Sortert {column} stigende',
            sortedByColumnDesc: 'Sortert {column} synkende',
            showHideSearch: 'Vis/skjul søkefelt',
            showHideColumns: 'Vis/skjul kolonner',
            showAllColumns: 'Vis alle kolonner',
            showAllRows: 'Vis alle rader',
            rowsPerPage: 'Rader per side:',
            of: 'av',
            toggleFullScreen: 'Vis i fullskjerm',
            toggleSelectAll: 'Velg alle',
            toggleSelectRow: 'Velg rad',
            goToLastPage: 'Gå til siste side',
            goToNextPage: 'Gå til neste side',
            goToFirstPage: 'Gå til første side',
            gotoLastPage: 'Gå til siste side',
            clearSelection: 'Nullstill valg',
            selectedCountOfRowCountRowsSelected: '{selectedCount} av {rowCount} valgt',
        },
        muiTablePaperProps: {
            sx: {
                display: 'flex',
                flexDirection: 'column',
            }
        },
    });

    /**
     * Load the list of files for the given operation.
     *
     * This function sends a POST request to the `/jobs/file-upload/get-files` endpoint
     * with the operation ID as parameter. The response is a list of files, which
     * is then set as the table data.
     *
     * @return {Promise<void>} A promise that resolves when the files are loaded.
     */
    const loadDocuments = useCallback(async () => {
        damsFetch('/jobs/file-upload/get-files', {
            method: 'POST',
            body: decamelize(JSON.stringify({
                operationId: operationId
            }))
        }).then(data => {
            setData(data);
        });
    }, [operationId]);

    /**
     * Handler triggered when the user clicks on the "Rediger metadata" button
     * above the table of files. Opens the dialog where the user can select
     * which fields to edit.
     */
    const handleEditMetadataClick = () => {
        appDispatch({
            type: FILE_UPLOAD_OPERATION_SELECT_FIELDS_DIALOG,
            open: true,
        });
    };

    /**
     * Clears the selection of rows in the table. This is called when the user
     * clicks the "Nullstill" button in the dialog that is opened by clicking
     * on the "Rediger metadata" button above the table of files.
     */
    const clearSelection = () => {
        batchEditDispatch({type: UNSELECT_ALL_FIELDS});   // Clear fields from the edit-form.
        table.resetRowSelection();
    };

    /**
     * Function that is called when the user has selected fields to edit in the files table.
     * It dispatches an action to the app context to show the edit metadata form with
     * the selected fields.
     *
     * @param selectedFields {string[]} The fields that were selected by the user to edit.
     */
    const showEditMetadataForm = (selectedFields) => {
        if (!selectedFields) {
            // User has cancelled the operation, clear selection and quit.
            clearSelection();
            return;
        }

        const selectedRows = table.getState().rowSelection;

        // Batch edit context keeps track of the selected fields.
        batchEditDispatch({
            type: SELECT_FIELDS,
            fields: selectedFields
        });

        appDispatch({
            type: FILE_UPLOAD_OPERATION_EDIT_METADATA_DIALOG,
            open: true,
            operationId: operationId,
            documentIds: Object.keys(selectedRows),
        });
    };

    /**
     * Called when the user clicks on the "Lagre" button in the edit metadata form.
     * The function resets the selected table rows and should also reload the
     * list of files in the table, but this is currently commented out.
     *
     * @param {string[]} editedDocumentIds The IDs of the documents that were edited.
     */
    const editMetadataCallback = (editedDocumentIds) => {
        clearSelection();

        if (!editedDocumentIds) {
            // User has cancelled the operation, quit.
            return;
        }

        // Reload list of documents.
        loadDocuments().then();
    };

    /**
     * Hook used to load the list of files for the given operation.
     */
    useEffect(() => {
        if (!loadData || !operationId) {
            return;
        }
        loadDocuments().then();
    }, [operationId, loadData, loadDocuments]);

    const rowSelection = table.getState().rowSelection;

    /**
     * Hook used to get the list of selected rows/unique IDs.
     */
    useEffect(() => {
        setSelectedRows(rowSelection);
    }, [rowSelection]);


    return columns && data &&
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto'
        }}>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start'
            }}>
                <Button onClick={handleEditMetadataClick}
                        disabled={Object.keys(selectedRows).length === 0}
                        variant={"contained"}
                        sx={{
                            marginBottom: '8px'
                        }}>
                    Rediger metadata
                </Button>
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto'
            }}>
                <MaterialReactTable table={table}/>
            </Box>

            <BatchEditProvider>
                <DialogSelectFields callback={showEditMetadataForm}/>
            </BatchEditProvider>

            {fileUploadOperationEditFiles.open
                && <DocumentProvider>
                    <SnackbarProvider>
                        <CopyrightProvider>
                            <DialogMetadataForm callback={editMetadataCallback}/>
                        </CopyrightProvider>
                    </SnackbarProvider>
                </DocumentProvider>
            }
        </Box>;
};