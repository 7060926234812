import {CANCEL_OPERATION} from "../app/AppContext";
import {damsFetch} from "../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";

/**
 * Cancels the given operation, removes it from the list of operations and tags it as cancelled in the database.
 *
 * @param {function} appDispatch - The app dispatch function from the AppContext.
 * @param {object} operation - The operation to cancel.
 * @param {function} callback - Optional callback function that will be called with the operation ID when the operation is successfully cancelled.
 */
export const cancelOperation = (appDispatch, operation, callback) => {
    appDispatch({
        type: CANCEL_OPERATION,
        operation: operation
    });

    damsFetch('/jobs/file-upload', {
        method: 'POST',
        body: JSON.stringify(decamelizeKeysDeep({
            action: 'cancel',
            operation: operation
        }))
    }).then(() => {
        callback(operation['jobId']);
    });
};