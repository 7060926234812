import {clientLog} from "../clientLog";
import {registerOperationInDb} from "./registerOperationInDb";
import {scheduleOperation} from "./scheduleOperation";


/**
 * Creates a new file upload operation.
 *
 * This function creates a new operation object, registers it
 * in the DAMS database to track its progress, and then
 * schedules the operation on the scheduler.
 *
 * @param {number} cId - The collection ID.
 * @param {number} mId - The museum ID.
 * @param {number} projectId - The project ID.
 * @param {function} successCallback - The callback function to call when the operation
 *                              is successfully created and scheduled.
 * @param {function} errorCallback - The callback function to call when the operation
 *                                   fails.
 *
 * @return {Promise<void>} The promise that resolves when the operation is
 *                         successfully created and scheduled.
 */
export const createOperation = async (cId, mId, projectId, successCallback, errorCallback) => {
    if (successCallback === undefined || successCallback === null || typeof successCallback !== 'function') {
        throw new TypeError('successCallback is not a function');
    }

    if (errorCallback === undefined || errorCallback === null || typeof errorCallback !== 'function') {
        throw new TypeError('errorCallback is not a function');
    }

    try {
        let operationObj = {
            collectionId: cId,
            museumId: mId,
            projectId: projectId,
        };

        // Schedule the file upload operation:
        const res = await scheduleOperation(operationObj);
        const {operation: scheduledOperation} = res;

        // Register the operation in the DAMS database, to track its progress:
        const registeredOperation = await registerOperationInDb(scheduledOperation, operationObj);

        if (registeredOperation) {
            successCallback(registeredOperation);
        } else {
            clientLog('warn', 'failed to register file upload operation', 'damsfileuploadhome');
        }
    } catch (e) {
        if (e.message === 'scheduler_no_connection') {
            clientLog('warn', 'unable to connect to scheduler', 'damsfileuploadhome');
        } else {
            clientLog('error', e, 'damsfileuploadhome');
        }
        errorCallback();
    }
};
