import React, {useEffect, useState} from "react";
import {useTheme} from "@emotion/react";
import {matchPath, useLocation, useNavigate} from "react-router-dom";
import {Step, Stepper} from "@mui/material";
import Box from "@mui/material/Box";
import StepLabel from "@mui/material/StepLabel";
import {ArcheologyRemoteSearchProject} from "./archeology/wizard/ArcheologyRemoteSearchProject";
import {ArcheologyProjectAddUsers} from "./archeology/wizard/ArcheologyProjectAddUsers";
import {ArcheologyRemoteProjectShowMetadata} from "./archeology/wizard/ArcheologyRemoteProjectShowMetadata";
import {CREATE_NEW_PROJECT, useProjectDispatch, useProjectState, useProjectTranslation,} from "./projectContext";
import {usePostFolder} from "../folder/usePostFolder";
import {ADD_FOLDER, useMyFoldersDispatch} from "../folder/MyFoldersContext";
import {useSnackbarDispatch} from "../snackbar/SnackbarContext";
import useDeepCompareEffect from "use-deep-compare-effect";
import Stack from "@mui/material/Stack";
import {clientLog} from "../clientLog";
import {GeneralProjectCreate} from "./general/wizard/GeneralProjectCreate";
import {DocumentProvider} from "../documents/documentContext";
import {ProjectViewProvider} from "./archeology/projectview/projectViewContext";
import {FoldersProvider} from "../folder/FoldersContext";
import {showSnackbar} from "../app/showSnackbar";

export const CreateProjectPage = () => {
    const t = useProjectTranslation();
    const theme = useTheme();
    const projectDispatch = useProjectDispatch();
    const folderDispatch = useMyFoldersDispatch();
    const snackbarDispatch = useSnackbarDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const {
        activeStep,
        projectType,
        projectData,
        collectionId,
        acls,
        cancel,
        save,
    } = useProjectState();

    const [postFolderResponse, postFolder] = usePostFolder();

    const [currentStep, setCurrentStep] = useState(0);
    const [projectConfig, setProjectConfig] = useState({});

    useEffect(() => {
        if (cancel) {
            navigate("/all-projects");
        }
    }, [cancel, navigate]);

    const selectedProjectType = matchPath(
        {
            path: "/new-project/:projectType",
            caseSensitive: false,
            end: true,
        },
        location.pathname
    ).params.projectType;

    const projectConfigs = [
        {
            name: "archeology",
            steps: [
                {
                    ix: 0,
                    key: "chooseProject",
                    label: t('chooseMuseumAndProject', 'Velg museum og prosjekt'),
                    page: <ArcheologyRemoteSearchProject/>,
                },
                {
                    ix: 1,
                    key: "downloadMetadata",
                    label: t('fetchMetadata', 'Hent inn metadata'),
                    page: <ArcheologyRemoteProjectShowMetadata/>,
                },
                {
                    ix: 2,
                    key: "addUsers",
                    label: t('addUsers', 'Gi brukere tilgang'),
                    page: <ArcheologyProjectAddUsers/>,
                },
            ],
        }
    ];

    const getProjectConfig = (pType) => {
        return projectConfigs.find((prj) => prj.name === pType);
    };

    const renderSteps = () => {
        return projectConfig?.steps?.map((s) => (
            <Step key={s.key}>
                <StepLabel>{s.label}</StepLabel>
            </Step>
        ));
    };

    const showPage = () => {
        return projectConfig?.steps?.find((step) => step.ix === currentStep)?.page;
    };

    useEffect(() => {
        projectDispatch({
            type: CREATE_NEW_PROJECT,
            projectType: selectedProjectType,
        });
    }, [projectDispatch, selectedProjectType]);

    useEffect(() => {
        if (projectType && projectType !== "") {
            setProjectConfig(getProjectConfig(projectType));
        }
    }, [projectType]);

    useEffect(() => {
        setCurrentStep(activeStep);
    }, [activeStep]);

    useEffect(() => {
        // Save project
        if (projectType && projectData && collectionId && save) {
            if (projectType === "archeology") {
                saveArcheologyProject();
            }
        }
    }, [save, collectionId, projectData, projectType]);

    useDeepCompareEffect(() => {
        // Project saved
        if (postFolderResponse.success) {
            folderDispatch({
                type: ADD_FOLDER,
                folder: postFolderResponse.newFolder,
            });

            showSnackbar(snackbarDispatch, 'Prosjekt', 'Prosjekt opprettet', 'success');

            if (postFolderResponse.newFolder.id) {
                setTimeout(() => {
                    navigate("/project/" + postFolderResponse.newFolder.id);
                }, 1500);
            }
        } else if (postFolderResponse.error) {
            clientLog('error', JSON.stringify(postFolderResponse.error), 'CreateProjectPage');
            showSnackbar(snackbarDispatch, 'Feil - Prosjekt', 'Opprettelse av prosjektet feilet', 'error');
        }
    }, [postFolderResponse.success, postFolderResponse.newFolder]);

    /**
     * Saves the archeology project as a folder with status "project"
     * and the folder_type as the specified "project_type".
     */
    const saveArcheologyProject = () => {
        const data = projectData.archeologyRemote;
        const {
            name,
            projectId,
            externalProjectUUID,
            updatedAt,
            projectType,
            projectStart,
            projectEnd,
            projectStatus,
            projectDescription,
            projectMonumentType,
            projectMonumentPeriod,
            projectDataset,
            projectLatLng,
            active,
            projectSystemId,
            projectSystemName
        } = data;
        const folder = {
            collectionId: collectionId,
            schemaId: 1,
            documentType: "Folder",
            locale: "no",
            status: "project",
            title: name,
            description: "Project folder",
            folder_type: "ProjectArcheology",
            content: {
                archeologyprojectref: {
                    id: projectId,
                    uuid: externalProjectUUID,
                    lastUpdated: updatedAt,
                    type: projectType,
                    startedAt: projectStart,
                    endedAt: projectEnd,
                    status: projectStatus,
                    description: projectDescription,
                    monumentType: projectMonumentType,
                    monumentPeriod: projectMonumentPeriod,
                    dataset: projectDataset,
                    latLng: projectLatLng,
                    active: active || true,
                    systemId: projectSystemId || '',
                    systemName: projectSystemName || ''
                },
                acls: acls?.map((a) => ({email: a, access: "write"})) || [],
            },
            acls: [], // In use by folders only, not projects!
        };
        postFolder(folder);
    };

    if (projectType === 'general') {
        return <FoldersProvider>
            <DocumentProvider>
                <ProjectViewProvider>
                    <GeneralProjectCreate t={t}/>
                </ProjectViewProvider>
            </DocumentProvider>
        </FoldersProvider>;
    } else {
        return (
            <Stack direction={"column"} alignItems={"center"} sx={{width: '100%', minWidth: '100%'}}>
                <Box sx={{marginTop: theme.spacing(4), width: "50%"}}>
                    <Stepper activeStep={activeStep}>{renderSteps()}</Stepper>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "80%",
                        paddingBottom: theme.spacing(2),
                        paddingTop: theme.spacing(4),
                    }}
                >
                    {showPage()}
                </Box>
            </Stack>
        );
    }
};
