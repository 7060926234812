import React, {useCallback, useEffect} from "react";
import {
    SET_DAMS_ID,
    SET_MUSEUM_COLLECTIONS,
    SET_MUSEUM_FEATURES,
    SET_USER_DATA,
    useAuthsDispatch,
    useAuthsState,
} from "./authsContext";
import {kitFetch} from "@ekultur/fetch";
import {damsFetch} from "../app/damsFetch";
import {getI18n} from "react-i18next";
import {clientLog} from "../clientLog";
import {useAppDispatch, useAppState} from "../app/AppContext";
import {resurrectFileUploadOperations} from "../operations/ressurect/resurrectFileUploadOperations";

export const WithUserdata = ({children}) => {

    const appDispatch = useAppDispatch();
    const authsDispatch = useAuthsDispatch();

    const {userData, userIsAuthenticated, museumCollections} = useAuthsState();
    const {operations} = useAppState();

    const authsDispatchCallback = useCallback(
        (action) => authsDispatch(action),
        [authsDispatch]
    );

    const username = userData?.name;

    const getMuseumsWithArcheologyModuleEnabled = (museums) => {
        kitFetch('/museum-api/feature-toggles/DAMSArkeologi/').then(json => {
            const museumsWithArcheologyFeature = json.map(d => d.museumId)
            museums = museums.map(m => (
                {
                    ...m,
                    features: {
                        archeology: museumsWithArcheologyFeature.includes(m.id)
                    }
                }));
            authsDispatch({type: SET_MUSEUM_FEATURES, museumFeatures: museums});
        });
    };

    const userAuthenticatedCallback = json => {
        authsDispatchCallback({
            type: SET_USER_DATA,
            userData: json,
        });

        damsFetch(`/collections/my-museum-collections/`)
            .then((data) => {
                authsDispatch({
                    type: SET_MUSEUM_COLLECTIONS,
                    museumCollections: data,
                });
                getMuseumsWithArcheologyModuleEnabled(data.map(d => ({
                    id: d.museumId,
                    collectionId: d.collectionId
                })));
            })
            .catch(err => {
                clientLog('error', err, 'WithUserdata');
            });

        damsFetch("/current-user/my-dams-id/")
            .then((damsIdJson) => {
                authsDispatch({
                    type: SET_DAMS_ID,
                    damsId: damsIdJson['id'],
                });
            })
            .catch(err => {
                clientLog('error', err, 'WithUserdata');
            });
    };

    useEffect(() => {
        if (userIsAuthenticated) {
            kitFetch(`/authz/authorize/userData`)
                .then(userAuthenticatedCallback)
                .catch((error) => {
                    clientLog('error', error, 'WithUserdata');
                });
        }
    }, [authsDispatchCallback, userIsAuthenticated, authsDispatch]);

    useEffect(
        () => {
            if (userData.locale) {
                getI18n().changeLanguage(userData.locale).then();
            }
        }, [userData.locale]
    )

    useEffect(() => {
        if (username) {
            damsFetch("/current-user/", {
                method: "POST",
                body: JSON.stringify({name: username}),
            }).then(null);
        }
    }, [username]);

    useEffect(() => {
        if (!userData || Object.keys(userData).length === 0 || museumCollections.length === 0) {
            return;
        }
        resurrectFileUploadOperations(userIsAuthenticated, userData, museumCollections, appDispatch, operations).then();
    }, [userData, museumCollections]);

    if (Object.keys(userData).length) {
        return <>{children}</>;
    } else {
        return null;
    }
};
