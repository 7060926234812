/**
 * Creates a new file upload operation object.
 *
 * @param {Object[]} museumCollections - List of objects containing the
 *   collection ID of each museum collection.
 * @param {Object} operationData - Object containing the job ID, collection ID,
 *   status and message of the operation.
 *
 * @returns {Object} An object representing the new file upload operation.
 *
 * @throws {Error} If museumCollections is not specified or has no values.
 * @throws {Error} If operationData is not specified.
 * @throws {Error} If museumId is not specified.
 */
export const createResurrectedFileOperationObject = (museumCollections, operationData) => {
    if (!museumCollections || !museumCollections.length || museumCollections?.length === 0) {
        throw Error('museumCollections is not specified or has no values');
    }

    if (!operationData || Object.keys(operationData).length === 0) {
        throw Error('operationData is not specified');
    }

    const {jobId, collectionId, status, message, files} = operationData;

    const museumId = museumCollections.find(m => m.collectionId === collectionId)?.museumId;
    if (!museumId) {
        throw Error('museumId is not specified');
    }

    // An operation worth resurrecting has files.
    if (!files || !Array.isArray(files) || files?.length === 0) {
        return null;
    }

    return {
        collectionId: collectionId,
        jobId: jobId,
        museumId: museumId,
        projectId: undefined,
        jobType: 'FILE_UPLOAD',
        files: files,
        workerMessages: [{
            id: message,
            status: status
        }]
    };
};