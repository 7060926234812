import React, {useEffect, useState} from "react";
import {useFoldersTranslation} from "../../folder/FoldersContext";
import {useSnackbarDispatch} from "../../snackbar/SnackbarContext";
import {TOGGLE_DELETE_FILES, useResultActionDispatch, useResultActionState,} from "../ResultActionContext";
import {damsFetch} from "../../app/damsFetch";
import {Box, Grow, Paper,} from "@mui/material";
import {PromisePool} from "@supercharge/promise-pool";
import {SET_DELETING, useSearchDispatch} from "../SearchContext";
import Modal from "@mui/material/Modal";
import {DeleteFilesModalActionButtons} from "./DeleteFilesModalActionButtons";
import {DeleteFilesModalHeader} from "./DeleteFilesModalHeader";
import PropTypes from "prop-types";
import {showSnackbar} from "../../app/showSnackbar";

/**
 * Renders a modal component for deleting documents from the search result.
 *
 * @param {object} selectedDocuments - The list of selected documents to be deleted
 * @param {function} onSuccess - The callback function to be called on successful deletion
 */
const DeleteFilesModal = ({selectedDocuments, onSuccess}) => {
    const {showDeleteFiles} = useResultActionState();
    const dispatch = useResultActionDispatch();
    const searchDispatch = useSearchDispatch();
    const snackbarDispatch = useSnackbarDispatch();
    const t = useFoldersTranslation();
    const [documents, setDocuments] = useState(null);

    useEffect(() => {
        setDocuments(selectedDocuments);
    }, [selectedDocuments]);

    /**
     * Deletes a document by ID using a DELETE request.
     *
     * @param {string} id - The ID of the document to delete
     * @return {Promise} A Promise that resolves with the deletion result
     */
    const deleteDocument = (id) => {
        return damsFetch("/documents/" + id, {
            method: "DELETE",
        })
            .then((json) => {
                return Promise.resolve(json);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    /**
     * Callback function for handling the success of deleting documents.
     *
     * @param {number} count - The number of documents deleted
     * @return {void}
     */
    const deletedDocumentsCallback = (count) => {
        if (count === 0) {
            return;
        }
        showSnackbar(snackbarDispatch,
            t("deleteFilesSuccessTitle", "Sletting fullført"),
            t("deleteFilesSuccessBody", "{{count}} filer er på slettet.", {count: count}),
            "success");
    };

    /**
     * Function that handles the callback for failed deletion of files.
     *
     * @param {number} count - The count of failed deletions
     * @return {void} No return value if count is 0, otherwise dispatches a snackbar message for the failed deletion.
     */
    const failedDeletionCallback = (count) => {
        if (count === 0) {
            return;
        }
        showSnackbar(snackbarDispatch,
            t("deleteFilesFailedTitle", "Sletting feilet"),
            t("deleteFilesFailedBody", "Filen ble ikke slettet."),
            "error");
    };

    const deleteDocuments = (docs) => {
        onHide();
        PromisePool
            .withConcurrency(4)
            .for(docs)
            .process(async (doc) => {
                return await deleteDocument(doc.uniqueId);
            })
            .then((res) => {
                failedDeletionCallback(res.errors.length);
                deletedDocumentsCallback(res.results.length);
                onSuccess(documents);
            });
    };

    const onHide = () => {
        dispatch({type: TOGGLE_DELETE_FILES});
    };

    const deleteFilesHandler = () => {
        if (documents === null || documents.length === 0) {
            return;
        }
        searchDispatch({type: SET_DELETING});
        deleteDocuments(documents);
    };

    return (<Modal
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            open={showDeleteFiles || false}
            closeAfterTransition
        >
            <Box sx={{
                outline: "none",
            }}>
                <Grow in={showDeleteFiles}>
                    <Paper sx={{
                        padding: "32px",
                        height: "100%",
                    }}>
                        <DeleteFilesModalHeader t={t} modalType={'delete'} selectedDocuments={selectedDocuments}/>
                        <DeleteFilesModalActionButtons t={t}
                                                       cancelCallback={onHide}
                                                       deleteCallback={deleteFilesHandler}/>
                    </Paper>
                </Grow>
            </Box>
        </Modal>
    );
};

DeleteFilesModal.propTypes = {
    "selectedDocuments": PropTypes.any.isRequired,
    "onSuccess": PropTypes.func.isRequired
};

export {DeleteFilesModal};