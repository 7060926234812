import {damsFetch} from "../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";

/**
 * Registers the operation in the DAMS database, to track its progress.
 *
 * This function registers the given operation in the DAMS database, so that
 * the progress of the operation can be tracked.
 *
 * @param {object} scheduledOperation - The operation object scheduled by the scheduler.
 * @param {object} operationObj - The operation object that triggered the job.
 *
 * @return {Promise<object>} The promise that resolves with the registered
 *                           operation object.
 *
 * @throws {Error} If an error occurs when registering the operation in the
 *                 DAMS database.
 *
 * @private
 */
export const registerOperationInDb = async (scheduledOperation, operationObj) => {
    if (scheduledOperation === null || scheduledOperation === undefined) {
        throw new Error('scheduledOperation is required');
    }

    if (operationObj === null || operationObj === undefined) {
        throw new Error('operationObj is required');
    }

    const registeredOperation = await damsFetch('/jobs/file-upload', {
        method: 'POST',
        body: JSON.stringify(decamelizeKeysDeep({
                action: 'register',
                operation: {
                    ...scheduledOperation,
                    ...operationObj
                }
            }
        ))
    });

    if (registeredOperation === null || registeredOperation === undefined) {
        throw new Error('DAMS database error');
    }

    const resultKeys = Object.keys(registeredOperation);
    if (!resultKeys.includes('jobId') || !resultKeys.includes('jobType')
        || !resultKeys.includes('messageDetails') || !resultKeys.includes('status')) {
        throw new Error('Unexpected response format');
    }

    return {
        ...operationObj,
        jobId: registeredOperation['jobId'],
        jobType: registeredOperation['jobType'],
        details: registeredOperation['messageDetails'],
        status: registeredOperation['status'],
    };
};