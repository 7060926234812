import {damsFetch} from "../../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";

/**
 * Loads incomplete operations from the server.
 *
 * @param {Object[]} museumCollections - List of objects containing the
 *   collection ID of each museum collection.
 *
 * @return {Promise<Object[]>} A promise that resolves to an array of objects
 *   representing the incomplete operations.
 */
export const loadIncompleteOperations = async (museumCollections) => {
    if (!museumCollections || !museumCollections.length || museumCollections?.length === 0) {
        throw Error('museumCollections is not specified or has no values');
    }
    return await damsFetch('/jobs/incomplete', {
        method: "POST",
        body: JSON.stringify(decamelizeKeysDeep({
            collection_ids: museumCollections.map(m => m.collectionId),
            job_type: 'FILE_UPLOAD'
        }))
    });
};
