import {damsFetch} from "../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {useSnackbarDispatch, useSnackbarTranslation,} from "../snackbar/SnackbarContext";
import {clientLog} from "../clientLog";
import {showSnackbar} from "../app/showSnackbar";

export const useAddSubject = () => {
    const snackbarDispatch = useSnackbarDispatch();
    const t = useSnackbarTranslation();

    const callback = json => {
        const {collectionId, id, uniqueId, locale, content, source, sourceId} = json;
        const {name} = content;


        return Promise.resolve({
            referenceType: "subject",
            _action: "put",
            collectionId: collectionId,
            reference: {
                id: id,
                uniqueId: uniqueId,
                locale: locale,
                _action: "put",
                title: name,
                status: "published",
                source: source,
                sourceId: sourceId,
                content: {
                    name: name,
                },
            },
        });
    };

    const errorCallback = error => {
        clientLog('error', error, 'useAddSubject');
        showSnackbar(snackbarDispatch,
            t("saveSubjectFailTitle", "Feil ved lagring av emneord"),
            t("saveSubjectFailBody", "Emneordet ble ikke lagret."),
            "error");
        return Promise.reject(error);
    };

    const createMappedSubjectObject = s => {
        const {collectionId, source, sourceId} = s;
        const title = s.content?.name || s.title;
        return {
            collectionId: collectionId,
            schemaId: 1,
            locale: "no",
            _action: "put",
            title: title,
            status: "published",
            source: source,
            sourceId: sourceId,
            documentType: "subject",
            content: {
                name: title,
            }
        };
    };

    return ({subjects}) => {
        return Promise.all(
            subjects.map((subject) => {
                const newSubject = createMappedSubjectObject(subject);
                return damsFetch(`/documents`, {
                    method: "POST",
                    body: JSON.stringify(decamelizeKeysDeep(newSubject)),
                })
                    .then(callback)
                    .catch(errorCallback);
            })
        );
    };
};
