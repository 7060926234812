import Paper from "@mui/material//Paper";
import {Input, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {damsFetch} from "../app/damsFetch";
import {extractMediaMeta} from "../dms/dmsSecurity";
import CircularProgress from "@mui/material/CircularProgress";
import {useDocumentTranslation} from "../documents/documentContext";
import {useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {UpdateFileSizeProperty} from "../patches/UpdateFileSizeProperty";
import {SearchProvider} from "../search/SearchContext";
import {getDmsIdFromModel} from "../utility";
import {clientLog} from "../clientLog";
import {showSnackbar} from "../app/showSnackbar";

/**
 * Admin component, that allows the user to enter an UUID for an object
 * in order to recreate/refresh the metadata for this object.
 * @returns {JSX.Element}
 * @constructor
 */
export const RegenerateMetadata = () => {

    const [modelId, setModelId] = useState('');
    const [processing, setProcessing] = useState(false)

    const t = useDocumentTranslation();
    const snackbarDispatch = useSnackbarDispatch();

    const handleInputChange = event => {
        setModelId(event.target.value);
    };

    const handleButtonClick = () => {
        if (!modelId) {
            return;
        }
        regenerateModelMeta(modelId);
    };

    const errorHandler = err => {
        clientLog('error', err, 'RegenerateMetadata');
        setProcessing(false);
        showSnackbar(snackbarDispatch,
            t('regenerateMetadata', 'Regenerer Metadata'),
            t('errorOccured', 'Det oppstod en feil'),
            'error');
    };

    const getModelCallback = json => {
        if (json.count === 0) {
            return;
        }
        const m = json.models[0];
        const collectionId = m.collectionId;
        const dmsId = getDmsIdFromModel(m);
        extractMediaMeta(collectionId, dmsId, true)
            .then(() => {
                setProcessing(false);
            })
            .catch(errorHandler);
    };

    const regenerateModelMeta = (modelId) => {
        setProcessing(true);
        const options = {
            method: 'POST',
            body: JSON.stringify({
                q: modelId,
                document_type: 'StillImage OR Audio OR Video OR Misc OR Dokument OR Geodata OR Tabell',
                sort: 'title asc'
            })
        };
        damsFetch('/search', options)
            .then(getModelCallback)
            .catch(errorHandler);
    };

    return <Box sx={{marginTop: '32px'}}>
        <Paper sx={{padding: '16px'}}>
            <Box sx={{maxWidth: '75vw', paddingBottom: '32px'}}>
                <Typography variant={"h3"} gutterBottom>{t('regenerateMetadata', 'Regenerer Metadata')}</Typography>
                <Typography variant={"body1"} gutterBottom>{
                    t('regenerateMetadataHelp', 'I enkelte tilfeller kan objekter være registrert med feil metadata. ' +
                        'Dette kan korrigeres ved å lime inn objektets unike ID i feltet nedenfor og trykke på ' +
                        '"Regenerer"-knappen. Applikasjonen vil da ekstrahere metadata på nytt fra valgte objekt.')}
                </Typography>
                <Stack direction={"row"} sx={{marginTop: '16px'}}>
                    {!processing &&
                        <>
                            <Input disabled={processing} onChange={handleInputChange} aria-label={"TEST"}
                                   sx={{marginRight: '16px', minWidth: '30vw'}}/>
                            <Button variant="contained" size="medium" onClick={handleButtonClick}>
                                {t('regenerate', 'Regenerer')}
                            </Button>
                        </>
                    }
                    {processing &&
                        <>
                            <CircularProgress size={30}/>
                            <Typography variant={"body1"}
                                        sx={{marginLeft: '16px'}}>
                                {t('processing', 'Prosesserer...')}
                            </Typography>
                        </>}
                </Stack>
            </Box>

            <Box>
                <SearchProvider>
                    <UpdateFileSizeProperty/>
                </SearchProvider>
            </Box>
        </Paper>
    </Box>;
};