import {Button, Dialog, DialogContent, Typography} from "@mui/material";
import {
    PROJECT_SHOW_ADMIN_OVERLAY,
    PROJECT_UPDATED,
    useProjectViewDispatch,
    useProjectViewState,
} from "./projectview/projectViewContext";
import {useSaveProject} from "./useSaveProject";
import React, {useEffect, useState} from "react";
import {useSnackbarDispatch,} from "../../snackbar/SnackbarContext";

import {ArcheologyProjectAdminUsers} from "./ArcheologyProjectAdminUsers";
import {useProjectTranslation} from "../projectContext";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {showSnackbar} from "../../app/showSnackbar";

export const ArcheologyProjectUserAdmin = () => {
    const t = useProjectTranslation();
    const projectViewDispatch = useProjectViewDispatch();
    const snackbarDispatch = useSnackbarDispatch();

    const {showAdmin, project} = useProjectViewState();

    const [archeology, success, error] = useSaveProject();

    const [users, setUsers] = useState([]);

    const projectId = project?.id;
    const collectionId = project?.collectionId;

    const hideOverlay = () => {
        projectViewDispatch({
            type: PROJECT_SHOW_ADMIN_OVERLAY,
            project: project
        });
    };

    const handleCancelClick = () => {
        hideOverlay();
    };

    const handleSaveClick = () => {
        const aclEmails = users.filter((u) => u.selected).map((u) => u.email);
        archeology(project.collectionId, project, aclEmails);
        projectViewDispatch({
            type: PROJECT_UPDATED,
            project: {
                ...project,
                content: {
                    ...project.content,
                    acls: aclEmails,
                },
            },
        });
    };

    const updatedUserList = (values) => setUsers(values);

    useEffect(() => {
        let msgObj = {
            title: t('projectUsersTitle', 'Prosjekt - Brukere'),
            message: t('projectUsersMessage', 'Valgte brukere har fått tilgang'),
            type: "success",
        };
        if (error) {
            msgObj = {
                title: t('projectUsersErrorTitle', 'Prosjekt - Brukere - Feil'),
                message: t('projectUsersErrorMessage', 'Det oppstod en feil ved lagring av brukere'),
                type: "error",
            };
        }
        if (error || success) {
            showSnackbar(snackbarDispatch, msgObj.title, msgObj.message, msgObj.type);
        }
    }, [success, error]);

    if (!showAdmin) {
        return <></>;
    }

    return (
        <Dialog open={true}>
            <DialogTitle>
                <Typography variant={"h6"}>{t('usersHeading', 'Brukere')}</Typography>
                <Typography variant={"body1"}>
                    {t('usersDescription', 'Kryss av for brukere som skal ha mulighet til å laste opp informasjon.')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <ArcheologyProjectAdminUsers
                    projectId={projectId}
                    collectionId={collectionId}
                    updatedUsersCallback={updatedUserList}
                />
            </DialogContent>

            <DialogActions>
                <Button color={"secondary"} onClick={handleCancelClick}>
                    {t('btnCancel', 'Avbryt')}
                </Button>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={handleSaveClick}
                    sx={{marginLeft: 2}}
                >
                    {t('btnSave', 'Lagre')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
