/**
 * Given a list of file upload operations, checks if the last step of the operation has been completed.
 * If the operation has `extractMetadata` set to `true`, the last step is considered to be
 * `extractingmetadata`. Otherwise the last step is considered to be `gettingobjectstatus`.
 *
 * @param {Array} fileUploadOperations A list of file upload operations.
 * @returns {boolean} `true` if the last step of the operation has been completed, `false` otherwise.
 */
export const isLastStepComplete = (fileUploadOperations) => {
    return Boolean(fileUploadOperations.find(o => {
        let state = false;
        if (!o.workerMessages || o.workerMessages?.length === 0) {
            state = false;
        }
        const lastMessage = o.workerMessages?.at(-1) || {id: null, status: null};
        const {id, status} = lastMessage;
        if (status === 'done' || status === 'error') {
            if (o.extractMetadata === true) {
                state = id === 'extractingmetadata';
            } else {
                state = id === 'gettingobjectstatus';
            }
        }
        return state;
    }));
};
