import {damsFetch} from "../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {useSnackbarDispatch, useSnackbarTranslation,} from "../snackbar/SnackbarContext";
import {clientLog} from "../clientLog";
import {showSnackbar} from "../app/showSnackbar";

export const useAddPerson = () => {
    const snackbarDispatch = useSnackbarDispatch();
    const t = useSnackbarTranslation();

    const callback = (json) => {
        return Promise.resolve({
            referenceType: "person",
            collectionId: json['collectionId'],
            reference: {
                id: json['id'],
                uniqueId: json['uniqueId'],
                locale: json['locale'],
                _action: "put",
                title: json['title'],
                nameAndTitle: json['nameAndTitle'],
                status: "published",
                source: json['source'],
                sourceId: json['sourceId'],
                content: {
                    name: json['content'].name,
                    nameAndTitle: json['content'].nameAndTitle,
                    yearOfBirth: json['content'].yearOfBirth,
                    yearOfDeath: json['content'].yearOfDeath,
                },
            },
        });
    };

    const errorCallback = error => {
        clientLog('error', error, 'useAddPerson');
        showSnackbar(snackbarDispatch, t("savePersonFailTitle", "Feil ved lagring av person"), t("savePersonFailBody", "Personen ble ikke lagret."), "error");
        return Promise.reject(error);
    };

    return ({persons}) => {
        return Promise.all(
            persons.map((person) => {

                if (!person.nameAndTitle) {
                    // Adding required data when creating a new "local" person.
                    const birthDate = person.yearOfBirth || '';
                    const deathDate = person.yearOfDeath || '';
                    const pName = person.name;
                    const personName = birthDate || deathDate ? `${pName} (${birthDate} - ${deathDate})` : pName;
                    person.name = personName;
                    person.nameAndTitle = personName;
                }

                const newPerson = {
                    collectionId: person.collectionId,
                    schemaId: 1,
                    locale: "no",
                    _action: "put",
                    title: person.name,
                    status: "published",
                    source: person.source,
                    sourceId: person.sourceId,
                    documentType: "person",
                    content: {
                        yearOfBirth: person.yearOfBirth,
                        yearOfDeath: person.yearOfDeath,
                        name: person.name,
                        nameAndTitle: person.nameAndTitle
                    },
                };

                return damsFetch(`/documents`, {
                    method: "POST",
                    body: JSON.stringify(decamelizeKeysDeep(newPerson)),
                })
                    .then(callback)
                    .catch(errorCallback);
            })
        );
    };
};
