import {Dialog, DialogActions, DialogContent} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {useAppState} from "../../app/AppContext";

/**
 * A dialog component that asks the user whether to extract metadata automatically or not.
 *
 * @param {boolean} openDialog - A boolean indicating whether the dialog should be open or not.
 * @param {object} operationId - The Id of the current operation.
 * @param {function} callback - A callback function that will be called with the updated operation object.
 * @returns {JSX.Element} A dialog component with a question and two buttons.
 */
export const DialogExtractMetadata = ({openDialog, operationId, callback}) => {

    const {operations} = useAppState();
    const [open, setOpen] = useState(false);
    const [currentOperation, setCurrentOperation] = useState();

    const setExtractionState = state => {
        return {
            ...currentOperation,
            extractMetadata: state
        };
    };

    const handleCancelClick = () => {
        setOpen(false);
        callback(setExtractionState(false));
    };

    const handleOkClick = () => {
        setOpen(false);
        callback(setExtractionState(true));
    };

    useEffect(() => {
        setOpen(openDialog);
    }, [openDialog]);

    useEffect(() => {
        if (!operationId) {
            return;
        }

        setCurrentOperation(operations.find(o => o.jobId === operationId));
    }, [operationId, operations]);


    return openDialog && currentOperation && <Dialog open={open} disableEscapeKeyDown={true}>
        <DialogTitle>
            Skal vi hente metadata fra filene?
        </DialogTitle>
        <DialogContent>
            Ønsker du at DAMS skal hente ut og bruke metadata fra de opplastede filene?
            Kun data som kan kartlegges mot felter i DAMS vil bli brukt.
        </DialogContent>
        <DialogActions>
            <Button color={"secondary"} onClick={handleCancelClick}>Tilbake</Button>
            <Button color={"primary"} variant={"contained"} onClick={handleOkClick}>Ok</Button>
        </DialogActions>
    </Dialog>;
};