import {damsFetch} from "../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";

/**
 * Schedules a job for the given operation.
 *
 * @param {object} operation The id of the operation to schedule.
 */
export const scheduleOperation = async (operation) => {
    const res = await damsFetch('/jobs/file-upload', {
        method: 'POST',
        body: JSON.stringify(decamelizeKeysDeep({
            action: 'schedule',
            operation: operation
        }))
    });

    if (res.error) {
        throw new Error(res.error);
    }

    return res;
};