/**
 * Checks if the given models contains any mapping configuration.
 *
 * @param {Object[]} models - The models to check.
 *
 * @returns {boolean} - True if the models contain any mapping configuration, otherwise false.
 */
export const configExists = (models) => {
    if (!models || models?.length === 0) {
        return false;
    }

    const mapped = models.map(m => m.content);
    if (!mapped || mapped?.length === 0) {
        return false;
    }

    let exists = false;
    for (let i = 0, max = mapped.length; i < max; i++) {
        Object.values(mapped[i]).forEach(v => {
            Object.values(v).forEach(vv => {
                if (vv.length > 0) {
                    exists = true;
                }
            });
        });
    }
    return exists;
};

