import {NOTIFY_MULTIPLE_FROM_WORKER, useAppDispatch} from "../../app/AppContext";
import {useCallback, useEffect} from "react";

/**
 * Listener used to listen for, and parse, messages sent by the upload web worker.
 *
 * @return {JSX.Element} An empty JSX element.
 */
export const UploadWorkerListener = () => {
    const appDispatch = useAppDispatch();

    const isValidMessage = event => {
        if (!event) {
            return;
        }

        if (!event.data) {
            return false;
        }

        const {channel, action} = event.data;
        return !(!channel || channel !== 'client' || !action || action === '');
    };

    const workerMessageParser = useCallback((event) => {
        if (!isValidMessage(event)) {
            return;
        }

        const data = event.data;
        const {action} = data
        const {data: statusData} = data;

        if (action === 'statusUpdate') {
            // Notify the GUI about file upload status changes.
            appDispatch({
                type: NOTIFY_MULTIPLE_FROM_WORKER,
                messages: statusData
            });
        }
    }, [appDispatch]);

    /**
     * Hook that listens for messages from the upload worker.
     */
    useEffect(() => {
        window._uploadWorkerInstance.onmessage = workerMessageParser;
    }, [workerMessageParser]);

    return <></>;
};