import {damsFetch} from "../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {useSnackbarDispatch, useSnackbarTranslation,} from "../snackbar/SnackbarContext";
import {clientLog} from "../clientLog";
import {showSnackbar} from "../app/showSnackbar";

/**
 * Adds a representation of a copyright clause, fetched from KulturNav to the DAMS database.
 * @returns {function(*, *=): Promise<{reference: {sourceId, _action: string, id, source, locale, title, uniqueId,
 * content: {name}, status: string}, referenceType: string, collectionId}>}
 */
export const useAddCopyrightClause = () => {
    const snackbarDispatch = useSnackbarDispatch();
    const t = useSnackbarTranslation();

    const docType = 'copyrightclause';

    const callback = json => {
        return Promise.resolve({
            referenceType: docType,
            collectionId: json['collectionId'],
            reference: {
                id: json['id'],
                uniqueId: json['uniqueId'],
                locale: json['locale'],
                _action: "put",
                title: json['title'],
                status: "published",
                source: json['source'],
                sourceId: json['sourceId'],
                content: {
                    name: json['content'].name,
                },
            },
        });
    };

    const errorCallback = error => {
        clientLog('error', error, 'useAddCopyrightClause');
        showSnackbar(snackbarDispatch,
            t("saveCopyrightClauseFailTitle", "Feil ved lagring av klausul"),
            t("saveCopyrightClauseFailBody", "Klausulen ble ikke lagret."),
            "error");
        return Promise.reject(error);
    };

    return (copyrightClause, _locale = "no") => {
        const damsCopyrightClause = {
            collectionId: copyrightClause.collectionId,
            schemaId: 1,
            locale: "no",
            _action: "put",
            title: copyrightClause.title,
            status: "published",
            source: copyrightClause.source,
            sourceId: copyrightClause.sourceId,
            documentType: docType,
            content: {
                name: copyrightClause.title,
            },
        };

        return damsFetch(`/documents`, {
            method: "POST",
            body: JSON.stringify(decamelizeKeysDeep(damsCopyrightClause)),
        })
            .then(callback)
            .catch(errorCallback);
    };
};
