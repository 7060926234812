import {Popper} from "@mui/material";
import React, {useEffect, useState} from "react";
import {FileUploadOperationDetails} from "./fileuploaddetails/FileUploadOperationDetails";
import {useAppState} from "../app/AppContext";

/**
 * A React component that displays a popper with a list of ongoing file upload operations.
 *
 * @param {object} props - The component props.
 * @param {HTMLElement} props.anchorEl - The element that the popper should be anchored to.
 * @param {string} props.id - The ID of the popper.
 * @param {boolean} props.open - Whether the popper is open or not.
 * @returns {ReactElement} The JSX element representing the popper with the list of ongoing file upload operations.
 */
export const FileUploadOperationsPopper = ({anchorEl, id, open}) => {

    const [openPopper, setOpenPopper] = useState(false);
    const {operations} = useAppState();

    const [uploadOperations, setUploadOperations] = useState([]);

    useEffect(() => {
        if (!operations) {
            return;
        }
        const fileUploadOperations = operations.filter(o => o.jobType === 'FILE_UPLOAD');
        setUploadOperations(fileUploadOperations);
    }, [operations]);

    useEffect(() => {
        setOpenPopper(open);
    }, [open]);

    return uploadOperations.length > 0 &&
        <Popper id={id}
                open={openPopper}
                anchorEl={anchorEl}
                placement={"bottom-end"}
                data-testid={"fileupload-operations-popper"}
                sx={{
                    zIndex: 1200,
                    height: '400px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    scrollbarWidth: 'thin',
                }}>
            {[...uploadOperations].map(o => {
                return <FileUploadOperationDetails key={`operation-details-${o.jobId}`}
                                                   header={'Opplasting'}
                                                   operation={o}/>;
            })}
        </Popper>;
};
