import {refreshToken} from '@ekultur/authentication'
import {clientLog} from "../clientLog";

/**
 * Method used to "keep alive" access tokens.
 * NOTE: This should only be called from the web workers.
 */
export const refreshCredentials = () => {
    const apiGateway = window._env_.REACT_APP_API_GATEWAY;
    if (!apiGateway) {
        throw new Error('API gateway is not defined');
    }
    refreshToken({
        apiGateway: apiGateway,
        onSuccess: () => {
            clientLog('info', 'refreshed access token');
        },
    });
}