import {useAppState} from "../../app/AppContext";
import {useEffect, useState} from "react";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * A React component used to display the progress of an ongoing file upload operation.
 *
 * The component expects a single prop, `operationId`, which is the ID of the operation to monitor.
 *
 * The component displays a linear progress bar, with a color of `secondary` and a variant of `determinate`.
 * The progress bar will be updated as the operation progresses.
 *
 * @param {string} operationId - The ID of the operation to monitor.
 * @returns {JSX.Element} The JSX element representing the progress bar of the ongoing file upload operation.
 */
export const OperationProgress = ({operationId}) => {

    const {operations} = useAppState();

    const [lastMessage, setLastMessage] = useState();

    const singleStateStages = ['workerstarted', 'operationcreated', 'filesuploaded', 'done'];
    const dualStateStages = ['creatingdamsdocuments', 'savingdamsdocuments', 'gettingobjectstatus'];

    useEffect(() => {
        if (operations.length === 0 || !operationId) {
            return;
        }

        const operation = operations.find(o => o.jobId === operationId);
        if (!operation) {
            return;
        }

        const message = operation.workerMessages?.at(-1);
        if (!message) {
            return;
        }
        setLastMessage(message);
    }, [operations, operationId]);


    const getPercentComplete = (message) => {
        const {id, status} = message;
        const total = singleStateStages.length + dualStateStages.length;
        let ix;
        let percentage = 0;
        if (['init', 'running'].includes(status)) {
            ix = singleStateStages.findIndex(s => s === id);
            if (ix !== -1) {
                percentage = Math.random(ix / total) * 100;
            }
        } else {
            ix = dualStateStages.findIndex(s => s === id);
            if (ix !== -1) {
                // Dual state stages are not complete until their state equals "done",
                // until then calculate progress based on the previous stage.
                if (ix === 0 || dualStateStages[ix].status === 'done') {
                    percentage = Math.round((singleStateStages.length + ix / total) * 100);
                } else {
                    percentage = Math.round((((singleStateStages.length + ix) - 1) / total) * 100);
                }
            }
        }
        return percentage;
    };

    return lastMessage && <LinearProgress color={"secondary"}
                                          variant={"determinate"}
                                          value={getPercentComplete(lastMessage)}/>;
};