import {damsFetch} from "../app/damsFetch";
import {useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {FOLDERS_DELETED, useFoldersDispatch} from "./FoldersContext";
import {UNSELECT_ALL} from "../search/SearchContext";

export const useDeleteFolder = (t) => {
    const snackbarDispatch = useSnackbarDispatch();
    const foldersDispatch = useFoldersDispatch();

    const getRequestPromise = (id) => {
        return new Promise((resolve, reject) => {
            damsFetch("/folders/" + id, {method: "DELETE"})
                .then((json) => {
                    resolve(json);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    };

    const showSnackbar = (success, failed) => {
        if (failed > 0) {
            showSnackbar(snackbarDispatch,
                t("deleteFolderFailedTitle", "Feil - Sletting foldere"),
                t("deleteFolderFailedBody", "{{count}} foldere av {{total}} ble ikke slettet", {
                    count: failed,
                    total: failed + success
                }),
                "error"
            );
        } else {
            showSnackbar(snackbarDispatch,
                t("deleteFolderSuccessTitle", "Sletting foldere"),
                t("deleteFolderSuccessBody", "{{count}} foldere ble slettet", {
                    count: success,
                }),
                "success");
        }
        foldersDispatch({
            type: FOLDERS_DELETED,
        });
        foldersDispatch({
            type: UNSELECT_ALL,
        });
    };

    const deleteFolders = (folderIds) => {
        const promises = [];
        folderIds.forEach((id) => {
            promises.push(getRequestPromise(id));
        });
        Promise.allSettled(promises).then((results) => {
            const failed =
                results.filter((r) => r.status === "rejected")?.length || 0;
            const success =
                results.filter((r) => r.status === "fulfilled")?.length || 0;
            showSnackbar(success, failed);
        });
    };

    return [deleteFolders];
};
