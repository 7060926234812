import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {Dialog, DialogActions, DialogContent, InputLabel, Select} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import {usePostDocuments} from "../documents/usePostDocuments";
import {useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {useFileUploadState, useFileUploadTranslation} from "./fileUploadContext";
import {showSnackbar} from "../app/showSnackbar";


/**
 * Renders a dialog component for editing the object type.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Indicates whether the dialog is open or not.
 * @param {Object} props.model - The model object containing the object type.
 * @param {Function} props.closeCallback - The callback function to close the dialog.
 * @param {Function} props.saveCallback - The callback function to save the updated object type.
 * @return {JSX.Element} The rendered dialog component.
 */
export const EditObjectTypeDialog = ({open, model, closeCallback, saveCallback}) => {
    const t = useFileUploadTranslation();

    // eslint-disable-next-line no-unused-vars
    const [postDocumentsResponse, postDocuments, postDocumentsInChunks] = usePostDocuments(); // NOSONAR

    const {uploadedFiles} = useFileUploadState();

    const snackbarDispatch = useSnackbarDispatch();

    const objectTypes = [
        {name: 'Dokument', value: 'Dokument'},
        {name: 'Tabell', value: 'Tabell'},
        {name: 'Geodata', value: 'Geodata'},
        {name: 'Lyd', value: 'Audio'},
        {name: 'Bilde', value: 'StillImage'},
        {name: 'Video', value: 'Video'},
        {name: '3D modell', value: 'Modell'},
        {name: 'Annet', value: 'Misc'}
    ];

    const [openState, setOpenState] = useState(false);
    const [selectedObjectType, setSelectedObjectType] = useState(model.documentType);

    const syncUploadedFiles = (updatedObject) => {
        const ix = uploadedFiles.findIndex(u => u.uniqueId === updatedObject.uniqueId);
        if (ix > -1) {
            uploadedFiles[ix] = updatedObject;
        }
    };

    const showError = () => {
        showSnackbar(snackbarDispatch, t("saveObjectTypeFailedTitle", "Feil ved lagring"),
            t("saveObjectTypeFailedBody", "Det oppstod en feil ved lagring"),
            "error");
    };

    const handleChange = event => {
        setSelectedObjectType(event.target.value);
    };

    const saveObject = object => {
        postDocumentsInChunks([object]).then(res => {
            if (res.errors.length > 0) {
                showError();
            } else {
                syncUploadedFiles(object);
                saveCallback(res.results[0]);
            }
        });
    }

    const saveHandler = () => {
        const updatedModel = {
            ...model,
            documentType: selectedObjectType
        };
        saveObject(updatedModel);
    };

    useEffect(() => {
        setOpenState(open);
    }, [open]);

    return <Dialog open={openState}>
        <DialogTitle>
            {t('dlgChangeObjectType', 'Endre Objekttype')}
        </DialogTitle>
        <DialogContent sx={{minWidth: '400px', width: '400px'}}>
            <Typography mt={2} mb={2}>
                {t('object', 'Objekt')}: {model.title}
            </Typography>
            <FormControl variant="filled" sx={{m: 1, minWidth: 120}}>
                <InputLabel id="select-label">
                    {t('chooseObjectType', 'Velg objekttype')}
                </InputLabel>
                <Select
                    labelId="select-label"
                    id="objecttype-select"
                    value={selectedObjectType}
                    onChange={handleChange}
                >
                    {
                        objectTypes.map(o => {
                            return <MenuItem key={o.value} value={o.value}>{o.name}</MenuItem>;
                        })
                    }
                </Select>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button color={"primary"} onClick={closeCallback}>
                {t('btnCancel', 'Avbryt')}
            </Button>
            <Button color={"secondary"} variant={"contained"} onClick={saveHandler}>
                {t('btnSave', 'Lagre')}
            </Button>
        </DialogActions>
    </Dialog>
};