import {damsFetch} from "../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";

/**
 * Executes a POST request to the DAMS API to perform the specified action on the given operation.
 *
 * @param {string} action - The action to be performed on the operation (e.g. 'add-files', 'reschedule', etc.).
 * @param {object} operation - The operation object containing relevant details.
 * @return {Promise<object>} A promise that resolves with the response from the DAMS API.
 */
export const executeApiRequest = async (action, operation) => {
    const validActions = [
        'register', 'add-files', 'reschedule', 'cancel',
        'schedule', 'modify', 'schedule-metadata-extraction',
        'resurrect'
    ];

    if (!validActions.includes(action)) {
        throw new Error(`Invalid action: ${action}`);
    }

    if (!operation) {
        throw new Error('Missing operation');
    }

    if (typeof (operation) !== 'object') {
        throw new Error('Invalid operation');
    }

    return await damsFetch('/jobs/file-upload', {
        method: 'POST',
        body: JSON.stringify(decamelizeKeysDeep({
            action: action,
            operation: operation
        }))
    });
};