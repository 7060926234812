import React, {useState} from "react";
import {Dialog, DialogActions, DialogContent, List, ListItem, ListItemText, Stack} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import {SelectMuseumDialog} from "../museum/SelectMuseum/SelectMuseumDialog";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsFetch} from "../app/damsFetch";
import {useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {clientLog} from "../clientLog";
import LoadingButton from "@mui/lab/LoadingButton";
import {showSnackbar} from "../app/showSnackbar";


/**
 * A dialog for creating an image import job.
 *
 * @param {Function} t - The translation function.
 * @param {Function} callback - The callback function to be called after the job is created.
 * @return {JSX.Element} The CreateJobDialog component.
 */
export const CreateJobDialog = ({t, callback}) => {
    const [open, setOpen] = useState(false);
    const [collectionId, setCollectionId] = useState(null);
    const [selectedMuseum, setSelectedMuseum] = useState(null);
    const [addingJob, setAddingJob] = useState(false);

    const [folderPath, setFolderPath] = useState(
        window._env_.REACT_APP_X_QUOTE_MODE === 'local'
            ? '/app/server/tests/static_assets' : '/ext_data/imageimport/nmf'
    );

    const [fileFilter, setFileFilter] = useState([
        {name: 'jpg', checked: true},
        {name: 'jpeg', checked: true},
        {name: 'tiff', checked: true},
        {name: 'tif', checked: true},
        {name: 'png', checked: false},
        {name: 'webp', checked: false},
        {name: 'gif', checked: false}
    ]);

    const snackbarDispatch = useSnackbarDispatch();

    /**
     * Gets the complete config. json for an image import job.
     * @returns {{ekultur: {appId: string, museumdId: *}, aws: {efs: {fileFilter: [{name: string, checked: boolean},{name: string, checked: boolean},{name: string, checked: boolean},{name: string, checked: boolean},{name: string, checked: boolean},null,null], sourceFolder: string}}}}
     */
    const getConfig = () => {
        return {
            "museum_id": selectedMuseum?.id,
            "source": folderPath,
            "filter": fileFilter.filter(f => f.checked).map(ff => ff.name),
        };
    };

    /**
     * Updates the folder path based on the event target value.
     *
     * @param {Event} event - The event object triggering the change.
     */
    const handleFolderPathChange = (event) => {
        setFolderPath(event.target['value']);
    };

    const handleFilterChange = event => {
        const name = event.target['computedName'];
        const checked = event.target.checked;

        // update file filters
        const tempFilters = [...fileFilter];
        const ix = tempFilters.findIndex(ff => ff.name === name);
        if (ix > -1) {
            tempFilters[ix] = {name: name, checked: checked};
        }
        setFileFilter(tempFilters);
    };

    const handleSelectedMuseum = museum => {
        setOpen(true);
        setCollectionId(museum.collectionId);
        setSelectedMuseum(museum)
    };

    const handleCancel = () => {
        setCollectionId(null);
        setSelectedMuseum(null);
        setFolderPath(null);
        setOpen(false);
    };

    const handleSave = () => {
        setAddingJob(true);

        const config = getConfig();
        const options = {
            method: 'POST',
            body: JSON.stringify(decamelizeKeysDeep(config)),
        };
        damsFetch('/jobs/register/image-import', options)
            .then(() => {
                clientLog('debug', 'added job - image import', 'createjobdialog');
                setOpen(false);
                setAddingJob(false);
                showSnackbar(snackbarDispatch, 'Jobb regisrert', 'Importjobb registrert', 'success');
                callback();
            })
            .catch((err) => {
                clientLog('error', err, 'createjobdialog');
                setAddingJob(false);
            });
    };

    return <>
        {
            !collectionId &&
            <SelectMuseumDialog t={t}
                                dialogTitle={t('imageImportSelectMuseumDialogTitle', 'Opprette jobb')}
                                selectedMuseumCallback={handleSelectedMuseum}
                                projectContext={false}/>
        }
        <Dialog open={open}>
            <DialogTitle>
                {t('imageImportDialogTitleCreateJobDialogTitle', 'Opprett jobb')}
            </DialogTitle>
            <DialogContent>
                <List>
                    <ListItem key={'item1'}>
                        <Stack direction={"column"}>
                            <ListItemText primary={'Path til filer/foldere'}
                                          secondary={
                                              <Typography variant={'caption'}>
                                                  {t('imageImportPathToFolder', 'Oppgi path til folderen inneholdende filene du ønsker å importere.')}
                                              </Typography>
                                          }/>
                            <TextField id={"folderPath"}
                                       name={"folderPath"}
                                       variant={"filled"}
                                       value={folderPath}
                                       size={"small"}
                                       onChange={handleFolderPathChange}
                            />
                        </Stack>
                    </ListItem>

                    <ListItem key={'item2'}>
                        <Stack direction={"column"}>
                            <ListItemText primary={'Filter'}
                                          secondary={
                                              <Typography variant='caption'>
                                                  {t('imageImportChooseFromList', 'Velg i listen filendelsen for filene du ønsker å importere.')}
                                              </Typography>
                                          }/>

                            <FormGroup onChange={handleFilterChange}>
                                {fileFilter.map(f => (
                                    <FormControlLabel control={<Checkbox defaultChecked={f.checked}/>}
                                                      label={f.name} key={`check-radio-${f.name}`}/>
                                ))}
                            </FormGroup>

                        </Stack>
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button disabled={addingJob} onClick={handleCancel}>
                    {t('imageImportBtnCancel', 'Avbryt')}
                </Button>
                <LoadingButton
                    variant={"contained"}
                    color={"secondary"}
                    onClick={handleSave}
                    loadingPosition={"end"}
                    loading={addingJob}
                    disabled={addingJob}
                >
                    {t('imageImportBtnSave', 'Lagre')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>;
};