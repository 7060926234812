import {ADD_MESSAGE} from "../snackbar/SnackbarContext";

/**
 * Displays a success message as a snackbar.
 *
 * @param {function} dispatch The dispatcher from the `SnackbarContext`.
 * @param {string} title The title of the message to display.
 * @param {string} message The message to display.
 */
export const showSnackbar = (dispatch, title, message, type) => {
    dispatch({
        type: ADD_MESSAGE,
        message: {
            title: title,
            body: message,
            type: type
        },
    });
};