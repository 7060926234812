import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsSearch} from "../../app/damsFetch";
import {clientLog} from "../../clientLog";


/**
 * Fetches the metadata mapping config for the given collection ID.
 *
 * @param {number} collectionId The ID of the collection.
 * @returns {Promise} A promise that resolves to the metadata mapping config.
 */
export const getMappingConfig = async (collectionId) => {
    if (!collectionId) {
        return;
    }

    try {
        const searchParams = new URLSearchParams(
            decamelizeKeysDeep({
                q: `*`,
                sort: "title asc",
                fq: "title:*",
                expand: true,
                documentType: `("field_config")`,
                collectionId: collectionId,
            })
        );
        const res = await damsSearch(searchParams);
        if (res.count > 0) {
            return res.models;
        } else {
            return [];
        }
    } catch (err) {
        clientLog('error', err, 'getmappingconfig');
    }
};

