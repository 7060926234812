import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {useAppState} from "../../app/AppContext";

/**
 * A component used to display the header of an ongoing file upload operation.
 *
 * @param {string} operationId - The ID of the operation.
 * @returns {JSX.Element} The JSX element representing the header of the ongoing file upload operation.
 */
export const OperationHeader = ({operationId}) => {

    const {operations} = useAppState();
    const [currentOperation, setCurrentOperation] = useState({workerMessages: [], files: []});

    const getHeader = () => {
        const {workerMessages, files} = currentOperation;
        const numFiles = files?.length || 0;

        if (!workerMessages || workerMessages.length === 0) {
            return `${numFiles} filer behandles i bakgrunnen`;
        }
        const lastMessage = workerMessages.at(-1);
        if (!lastMessage) {
            return `${numFiles} behandles i bakgrunnen`;
        }
        const {id, status} = lastMessage;
        if (status === 'done' && id === 'gettingobjectstatus') {
            return `${numFiles} filer er ferdig behandlet`;
        } else {
            return `${numFiles} filer behandles i bakgrunnen`;
        }
    };

    useEffect(() => {
        const operation = operations.find(o => o.jobId === operationId);
        setCurrentOperation(operation)
    }, [operations, operationId]);

    return currentOperation && <Typography variant={"h6"}>{getHeader()}</Typography>;
}