/*
    1. Select museum or project
    2. Select files
    3. Create the job, save to DB
        - job ID - UUID
        - collection ID - ID
        - project ID - ID
        - user ID - ID
        - files - Array
    4. Upload files
    5. Save updated job state to DB
 */

import {useEffect, useState} from "react";
import {SelectMuseumDialog} from "../museum/SelectMuseum/SelectMuseumDialog";
import {SelectFilesDialog} from "./SelectFilesDialog";
import {DocumentProvider} from "../documents/documentContext";
import {ADD_OPERATION, useAppDispatch, useAppState, useAppTranslation} from "../app/AppContext";
import {useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {useNavigate} from "react-router-dom";
import {showSnackbar} from "../app/showSnackbar";
import {createOperation} from "../operations/createOperation";

/**
 * A React component that handles the DAMS file upload workflow.
 *
 * This component guides the user through the following steps:
 * 1. Select museum or project
 * 2. Select files
 */
export const DamsFileUploadHome = () => {
    const {operations} = useAppState();
    const appDispatch = useAppDispatch();
    const snackbarDispatch = useSnackbarDispatch();
    const navigate = useNavigate();

    const [projectId, setProjectId] = useState();
    const [operation, setOperation] = useState(); // Keeps track of the current operation object.
    const [openMuseumDialog, setOpenMuseumDialog] = useState(false);
    const [showSelectFilesDialog, setShowSelectFilesDialog] = useState(false);

    const t = useAppTranslation();


    /**
     * Opens the dialog for selecting files to upload.
     */
    const openSelectFilesDialog = () => {
        setShowSelectFilesDialog(true);
    };

    const handleSelectedMuseum = async (museum) => {
        setOpenMuseumDialog(false);
        // await createOperation(museum.collectionId, museum.id);
        await createOperation(museum.collectionId, museum.id, undefined,
            (operationObj) => {
                // For local reference.
                setOperation(operationObj);

                // Add to list of ongoing operations.
                appDispatch({
                    type: ADD_OPERATION,
                    operation: operationObj
                });
            },
            () => {
                showSnackbar(snackbarDispatch,
                    'Filopplasting', 'Det oppstod en feil. Prøv igjen senere.', 'error');
                navigate('/');
            });
        openSelectFilesDialog();
    };

    const getSelectMuseumDialog = () => {
        if (!openMuseumDialog) {
            return <></>;
        }

        //  projectContext = false => Displays all museums
        //  projectContext = true => Displays only museums with projects module enabled
        return <SelectMuseumDialog t={t}
                                   collectionId={operation?.collectionId}
                                   dialogTitle={"Filopplasting"}
                                   selectedMuseumCallback={handleSelectedMuseum}
                                   projectContext={false}/>;
    };

    const getSelectFilesDialog = () => {
        if (!operation || !showSelectFilesDialog) {
            return <></>;
        }
        return <DocumentProvider>
            <SelectFilesDialog operation={operation}/>
        </DocumentProvider>;
    };

    /**
     * Hook used to determine which dialog to display.
     */
    useEffect(() => {
        if (!operation) {
            setOpenMuseumDialog(true);
        } else if (operation) {
            openSelectFilesDialog();
        }
    }, [operation, operations]);


    return <>
        {getSelectMuseumDialog()}
        {getSelectFilesDialog()}
    </>;
};