import {damsFetch} from "../app/damsFetch";
import {useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {useTranslation} from "react-i18next";
import {showSnackbar} from "../app/showSnackbar";

export const useEmail = () => {
    const snackbarDispatch = useSnackbarDispatch();
    const {t} = useTranslation("dams.email", {useSuspense: false});

    /**
     * Displays a "statusreport" when the e-mails have been sent.
     * @param data
     * @param snackbarTitle
     */
    const showStatusReport = (data, snackbarTitle) => {
        const failed = data
            .filter((r) => r.success === false)
            .map((i) => i.recipient);
        if (failed && failed.length > 0) {
            showSnackbar(snackbarDispatch,
                snackbarTitle,
                `${t("shareSnackErrorMessage", "Mailutsendelse feilet til")}: ${failed}`,
                "error");
        } else {
            showSnackbar(snackbarDispatch,
                snackbarTitle,
                `${t("shareSnackSuccessMessage", "Mail sendt til")}: ${data.map((i) => i.recipient)}`,
                "success");
        }
    };

    const sendSingleMail = async (subject, message, recipient) => {
        try {
            const opts = {
                method: "POST",
                body: JSON.stringify({
                    recipients: [recipient],
                    subject: subject,
                    body: message,
                })
            };
            return await damsFetch("/share/email", opts);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const sendEmail = (subject, message, recipients, snackbarTitle) => {
        if (!recipients || recipients.length === 0) {
            return;
        }

        damsFetch("/share/email", {
            method: "POST",
            body: JSON.stringify({
                recipients: recipients,
                subject: subject,
                body: message,
            }),
        }).then((data) => {
            if (data.error) {
                showSnackbar(
                    snackbarDispatch,
                    snackbarTitle,
                    t(
                        "shareSnackDefaultErrorMessage",
                        "Utsendelse av e-mail feilet, kontakt system administrator."
                    ),
                    "error"
                );
            } else {
                showStatusReport(data, snackbarTitle);
            }
        });
    };

    return [sendEmail, sendSingleMail];
};
