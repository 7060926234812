import {useAppState} from "../app/AppContext";
import React, {useCallback, useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import {FileUploadOperationsPopper} from "./FileUploadOperationsPopper";
import {isProgressDetailsPresent} from "./isProgressDetailsPresent";
import {isLastStepComplete} from "./isLastStepComplete";

/* Example FILE_UPLOAD operation object:
{
    "id": "265cf78b-f71d-40dc-8a7d-6aa84c401962",
    "collectionId": 3,
    "museumId": 20,
    "jobId": "265cf78b-f71d-40dc-8a7d-6aa84c401962",
    "jobType": "FILE_UPLOAD",
    "details": {
        "operation": {
            "id": "265cf78b-f71d-40dc-8a7d-6aa84c401962",
            "museumId": 20,
            "collectionId": 3
        }
    },
    "status": "init",
    "workerMessages": [
        {
            "id": "workerstarted",
            "details": null
        }
    ],
    "lastUpdated": 1726649673934
}
*/


/**
 * A component used to display a popper with a list of ongoing file upload operations.
 *
 * @return {JSX.Element} The JSX element representing the popper with the list of ongoing file upload operations.
 */
export const OperationsPopper = () => {
    const {operations} = useAppState();

    const [anchorEl, setAnchorEl] = useState(null);
    const [fileUploadOperations, setFileUploadOperations] = useState([]);

    const operationBox = useRef(null);

    const open = Boolean(anchorEl);

    const id = open ? 'operations-popper' : undefined;

    /**
     * Filters the list of operations based on the given operation type.
     *
     * @param {string} operationType - The type of operation to filter by.
     * @return {Array} A list of operations that match the given operation type.
     */
    const getOperationsByType = useCallback((operationType) => {
        if (!operations || operations.length === 0) {
            return [];
        }
        return operations.filter(o => o.jobType === operationType);
    }, [operations]);

    /**
     * Handles click on the operations dialog, to display the list of ongoing operations.
     * @param event
     */
    const handleClick = (event) => {
        if (operations?.length === 0) {
            return;
        }

        if (anchorEl === event.currentTarget && !isLastStepComplete(fileUploadOperations)) {
            setAnchorEl(null);
        } else if (isProgressDetailsPresent('FILE_UPLOAD', fileUploadOperations)) {
            // Only show the popper if there is at least one ongoing file upload operation, with a detailed message.
            setAnchorEl(event.currentTarget);
        }
    };

    /**
     * Hook used to get a list of operations with the given type.
     */
    useEffect(() => {
        setFileUploadOperations(getOperationsByType('FILE_UPLOAD'));
    }, [operations, getOperationsByType]);

    /**
     * Hook used to display the popper when the last operation is complete.
     */
    useEffect(() => {
        if (!isLastStepComplete(fileUploadOperations)) {
            setAnchorEl(null);
        } else {
            operationBox.current.click();
        }
    }, [isLastStepComplete(fileUploadOperations)]);

    // TODO: add support for other operation types!

    if (!operations || operations.length === 0) {
        return <></>;
    } else if (operations && operations.length > 0) {
        return <>
            <FileUploadOperationsPopper anchorEl={anchorEl} id={id} open={open}/>
            <Box ref={operationBox}
                 aria-describedby={id}
                 onClick={handleClick}
                 sx={{
                     position: 'absolute',
                     top: '63px',
                     right: '24px',
                     height: '60px',
                     maxHeight: '60px',
                     borderRight: 'solid 1px #bbb',
                     borderLeft: 'solid 1px #bbb',
                     borderBottom: 'solid 1px #bbb',
                     display: 'flex',
                     flexDirection: 'column',
                     padding: '16px',
                     cursor: 'pointer',
                     backgroundColor: '#fff',
                     zIndex: 999
                 }}>
                <Typography variant={"subtitle2"}>{operations.length} aktive operasjoner</Typography>
                <LinearProgress variant={"indeterminate"} color={"secondary"} sx={{width: '100%'}}/>
            </Box>
        </>;
    }
};