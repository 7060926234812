import {useCallback, useEffect, useState} from "react";
import {clientLog} from "../../clientLog";
import Typography from "@mui/material/Typography";
import {useAppState} from "../../app/AppContext";

/**
 * React component that displays the last message received from a file upload operation.
 *
 * The component is given the ID of the operation, and displays the last message
 * received from the operation. If the message is an error, it is also logged to the console.
 *
 * @param {string} operationId - The ID of the file upload operation.
 * @returns {ReactElement} The OperationStage component.
 */
export const OperationStage = ({operationId}) => {

    const {operations} = useAppState();

    const [stage, setStage] = useState();

    const getCreatingDamsObjectsStage = useCallback((status, details) => {
        if (status === 'start') {
            return 'Oppretter DAMS objekter';
        } else if (status === 'done') {
            return 'DAMS objekter opprettet';
        } else if (status === 'error') {
            clientLog('warn',
                'failed to create DAMS objects: ' + JSON.stringify(details), 'fileupload');
            return 'Oppretter DAMS objekter - feil';
        } else {
            return '';
        }
    }, []);

    const getSavingDamsObjectsStage = useCallback((status, details) => {
        if (status === 'start') {
            return 'Lagrer DAMS objekter';
        } else if (status === 'done') {
            return 'DAMS objekter lagret';
        } else if (status === 'error') {
            clientLog('warn',
                'failed to save DAMS objects: ' + JSON.stringify(details), 'fileupload');
            return 'Lagrer DAMS objekter - feil';
        } else {
            return '';
        }
    }, []);

    const getObjectStatusStage = useCallback((status, details) => {
        if (status === 'start') {
            return 'Henter konverteringsstatus';
        } else if (status === 'done') {
            return 'Konverteringsstatus hentet';
        } else if (status === 'error') {
            clientLog('warn',
                'failed to get object status: ' + JSON.stringify(details), 'fileupload');
            return 'Henter konverteringsstatus - feil';
        } else {
            return '';
        }
    }, []);

    const getFilesUploadedStage = useCallback((status, details) => {
        if (status === 'running') {
            return 'Filer lastet opp';
        } else {
            return '';
        }
    }, []);

    const getHrStage = useCallback((message) => {
        let hr;
        const {id, status, details} = message;
        switch (id) {
            case 'operationcreated':
                hr = 'Operasjon started';
                break;
            case 'filesuploaded':
                hr = getFilesUploadedStage(status, details);
                break;
            case 'creatingdamsobjects':
                hr = getCreatingDamsObjectsStage(status, details);
                break;
            case 'savingdamsobjects':
                hr = getSavingDamsObjectsStage(status, details);
                break;
            case 'gettingobjectstatus':
                hr = getObjectStatusStage(status, details);
                break;
            default:
                hr = '';
                break;
        }
        return hr;
    }, [getCreatingDamsObjectsStage, getSavingDamsObjectsStage, getObjectStatusStage]);

    useEffect(() => {
        if (operations.length === 0 || !operationId) {
            return;
        }
        const operation = operations.find(o => o.jobId === operationId) || {workerMessages: []};
        const {workerMessages} = operation;
        if (workerMessages?.length === 0) {
            return;
        }
        const lastMessage = workerMessages?.at(-1);
        if (!lastMessage) {
            return;
        }
        setStage(getHrStage(lastMessage));
    }, [operations, operationId, getHrStage]);

    return operationId
        && stage
        && <Typography>{stage}</Typography>;
};